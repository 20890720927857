<div class="container mx-auto">
  <div
    class="block px-6 mt-5 font-extrabold md:font-black text-3xl md:hidden md:px-0"
  >
    <p class="text-sm text-[#B01E31]">DEVELOPER</p>
    Seamlessly integrate <br />
    payment processing<br />
    into your business<br />
    solutions with Payfixy’s<br />
    APIs
  </div>
  <div class="developer">
    <div
      class="flex justify-center text-white text-4xl text-center mt-10 py-20"
    >
      <div class="hidden md:block md:text-[55px] leading-[48px;]">
        <p class="text-lg md:mb-5">DEVELOPER</p>
        Seamlessly integrate payment<br />
        processing into your business solutions<br />
        with Payfixy’s APIs
      </div>
    </div>
  </div>
</div>

<div
  class="relative flex flex-col mt-10 mb-10 md:mt-48 md:mb-48 md:flex-row items-center mx-auto space-y-0"
>
  <!-- left item -->
  <div class="container flex flex-col mx-auto px-6">
    <p
      class="max-w-2xl text-left md:text-[16px] text-[18px] md:font-bold font-semibold font-extrabold"
    >
      If you’re looking to build world-class business solutions and
      streamline<br />
      your payment processes, we highly recommend integrating with Payfixy’s
      APIs.
    </p>
    <p
      class="text-left max-w-2xl text-left md:text-[16px] text-[18px] font-extrabold mt-5"
    >
      With our robust APIs, you can easily integrate payment processing into
      your<br />
      business solutions, no matter what industry you’re in. Our platform is
      designed<br />
      to be flexible and adaptable, allowing you to customise the payment
      experience<br />
      to meet your specific needs.
    </p>
    <p
      class="text-left max-w-2xl text-left md:text-[16px] text-[18px] md:font-bold font-semibold mt-5"
    >
      Once you’ve set up your dashboard and integrated our APIs, you can
      start<br />
      receiving payments quickly and efficiently. Our platform supports a
      variety of<br />
      payment options, including credit cards, debit cards, and e-wallets,
      ensuring<br />
      that your customers have the flexibility they need to complete their
      transactions.
    </p>

    <p
      class="text-left max-w-2xl text-left md:text-[16px] text-[18px] md:font-bold font-semibold mt-5"
    >
      Plus, with our secure and reliable payment processing capabilities, you
      can trust<br />
      that all transactions will be processed accurately and without any issues.
    </p>
    <div class="mt-10 flex">
      <div class="getStarted mr-5">
        <button
          (click)="sales()"
          class="px-6 p-2 font-bold bg-brightRed text-white text-xs rounded-md baseline md:block md:bg-faintBtn md:text-brightRed"
        >
          Get Started
        </button>
      </div>
      <div>
        <div class="underline text-brightRed cursor-pointer font-semibold pt-1">
          API Documentation
        </div>
      </div>
    </div>
  </div>
  <!-- image -->
  <img
    src="assets/images/dev_img.png"
    class="hidden absolute right-0 xl:h-[48rem] md:h-[20rem] md:block"
    alt=""
  />
  <!-- <div id="image" class="hidden md:block">
    </div> -->
</div>

<div class="flex justify-center text-center mt-20 bg-[#b01e31] py-20">
  <div>
    <div class="mt-5 text-white md:font-bold text-2xl md:text-5xl">
      Get your business started with simplified <br />
      digital payment today!
    </div>
    <div class="flex justify-center mt-10 mb-10">
      <button
        (click)="sales()"
        class="px-6 p-2 font-bold text-[#b01e31] text-xs bg-white rounded-md baseline md:block"
      >
        Get Started
      </button>
    </div>
  </div>
</div>
