<section>
  <div
    class="relative flex flex-col mt-10 md:mt-20 md:flex-row items-center mx-auto space-y-0"
  >
    <!-- left item -->
    <div class="flex flex-col container mx-auto px-6 space-y-6">
      <h2
        class="max-width-m font-extrabold text-3xl text-left md:text-5xl md:font-black font-sanMedium text-[#B01E31]"
      >
        INTEGRATED MANAGEMENT SYSTEM POLICY
      </h2>
      <p class="text-[25px] font-black font-sanMedium">
        Date of Policy Notice:
      </p>

      <p class="text-left md:text-[16px] text-[18px] font-semibold">
        May 23, 2023
      </p>

      <p class="text-[25px] font-black font-sanMedium">Introduction</p>

      <div>
        <p class="font-black text-[19px] text-[#B01E31]">1 Introduction</p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold my-4">
          This policy defines how Management Systems will be set up, managed,
          measured, reported on and developed within Payfixy Limited.
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold">
          Payfixy Limited has decided to pursue full certification to ISO/IEC
          27001:2013 and ISO/IEC 22301:2019 (IMS) in order that the effective
          adoption of information security and Business continuity best practice
          may be validated by an external third party.
        </p>
      </div>
      <div>
        <p class="font-black text-[19px] text-[#B01E31]">1.1. Purpose</p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold">
          The purpose of this document is to define an overall policy regarding
          management systems that is appropriate to the purpose of Payfixy
          Limited and includes:
        </p>
        <div class="px-4">
          <ul>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              o A framework for setting objectives
            </li>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              o A commitment to satisfying applicable requirements
            </li>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              o A commitment to continual improvement of the management systems
            </li>
          </ul>
        </div>
        <p class="text-left md:text-[16px] text-[18px] font-semibold">
          This Policy is available in electronic form and will be communicated
          within the organisation and to all relevant stakeholders and
          interested third parties.
        </p>
      </div>

      <div>
        <p class="font-black text-[19px] text-[#B01E31]">
          1.2 IMS Policy Statement
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          ▪ Payfixy Limited’s current strategy and Integrated Management System
          provide the context for identifying, assessing, evaluating and
          controlling information/process/service-related risks through the
          establishment and maintenance of the IMS. The risk assessment and risk
          treatment plan capture how identified risks are controlled in
          alignment with Payfixy Limited’s risk management strategy.
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold my-4">
          ▪ In particular, business continuity and contingency plans, data
          backup procedures, access control to systems and information security
          incident reporting are fundamental to this policy. All employees of
          Payfixy Limited shall have the responsibility of reporting incidents.
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold my-4">
          ▪ All employees of Payfixy Limited and external parties identified in
          the IMS are expected to comply with this policy. All staff and certain
          external parties will receive or be required to provide appropriate
          training.
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold my-4">
          ▪ A current version of this document is available to all members of
          staff on the Intranet. This policy is issued on a version-controlled
          basis under the signature of the Managing Director / Chief Executive
          Officer, Payfixy Limited.
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold my-4">
          ▪ Breach of the policy or security mechanism may warrant disciplinary
          measures, up to and including termination of employment/contract as
          well as legal action in line with the Cybercrime Prohibition Act 2015.
        </p>

        <p class="font-black text-[19px] text-[#B01E31]">
          Payfixy Limited defines the core objectives or purpose of the IMS
          listed below:
        </p>
      </div>

      <div class="px-4">
        <ul>
          <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
            1. Understand the needs of Payfixy Limited and the necessity for
            establishing Business Continuity, Information Security Management
            System policy and objectives.
          </li>
          <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
            2. Implement and operate controls and measures for managing the
            overall capability of Payfixy Limited to manage disruptive
            incidents, Information security and its IT assets.
          </li>
          <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
            3. Monitor and review the performance and effectiveness of the BCMS,
            ISMS.
          </li>
          <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
            4. Continually improve Payfixy Limited’s integrated management
            system based on objective measurement.
          </li>
        </ul>

        <p class="text-left md:text-[16px] text-[18px] font-semibold my-4">
          Business continuity objectives will be documented in the Business
          Continuity Management Plan for the relevant financial year, together
          with details of a plan for how they will be achieved. Once approved,
          this plan will be reviewed on a quarterly basis as part of the
          management review process, at which time the objectives will also be
          reviewed to ensure that they remain valid. If amendments are required,
          these will be managed through the organizational change management
          process.
        </p>
      </div>
      <div>
        <p class="font-black text-[19px] text-[#B01E31]">2 IMS Policy</p>

        <p class="font-black text-[19px] text-[#B01E31]">
          2.2 Scope of the IMS
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold">
          For the purposes of certification within Payfixy Limited, the
          boundaries of the Management Systems are defined in the Payfixy
          Limited IMS0401 Context, Requirements and Scope.
        </p>
      </div>
      <div>
        <p class="font-black text-[19px] text-[#B01E31]">2.3 Requirements</p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          A clear definition of the requirements for the Management Systems will
          be agreed upon and maintained with the business so that all activities
          are focussed on the fulfilment of
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          <span class="font-bold">Document Classification:</span> Internal those
          requirements. Statutory, regulatory and contractual requirements will
          also be documented and input to the planning process. Specific
          requirements regarding the security of new or changed systems or
          services will be captured as part of the design stage of each project.
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          It is a fundamental principle of the Payfixy Limited Integrated
          Management System that the controls implemented are driven by business
          needs and this will be regularly communicated to all staff through
          team meetings and briefing documents.
        </p>
      </div>
      <div>
        <p class="font-black text-[19px] text-[#B01E31]">
          2.4 Top Management Leadership and Commitment
        </p>

        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          Commitment to the Management Systems extends to senior levels of the
          organization and will be demonstrated through this IMS Policy and the
          provision of appropriate resources to provide and develop the
          management systems and associated controls.
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          Top management will also ensure that a systematic review of the
          performance of the programme is conducted on a regular basis to ensure
          that objectives are being met and issues are identified through the
          audit programme and management processes. Management Review can take
          several forms including departmental and other management meetings.
        </p>
      </div>

      <div>
        <p class="font-black text-[19px] text-[#B01E31]">
          2.5 Framework for Setting Objectives and Policy
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          The high-level objectives for information security within Payfixy
          Limited are defined within the document “Payfixy Limited IMS0401
          Context, Requirements and Scope”. These are fundamental to the nature
          of the business and should not be subject to frequent change
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          These overall objectives will be used as guidance in the setting of
          lower-level, more short-term objectives within an annual cycle timed
          to coincide with organisational budget planning. This will ensure that
          adequate funding is obtained for the improvement activities
          identified. These objectives will be based upon a clear understanding
          of the overall business requirements, informed by the annual
          management review with stakeholders.
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          IMS objectives will be documented for the relevant financial year,
          together with details of how they will be achieved. These will be
          reviewed on a quarterly basis to ensure that remain valid. If
          amendments are required, these will be managed through the change
          management process.
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          In accordance with ISO/IEC 27001:2013, the control objectives and
          policy statements detailed in Annex A of the standard will be adopted
          where appropriate by Payfixy Limited. These will be reviewed on a
          regular basis in light of the outcome of risk assessments and in line
          with Payfixy Limited IMS0601 IMS Risk Assessment and Treatment
          Process. For references to the controls that implement each of the
          policy statements given please see Payfixy Limited IMS0604 Statement
          of Applicability.
        </p>
      </div>

      <div>
        <p class="font-black text-[19px] text-[#B01E31]">
          2.6 Roles and Responsibilities
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          Within the field of Information Security Management, there are a
          number of key roles that need to be undertaken to ensure the
          successful protection of the business from risk.
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          Full details of the responsibilities associated with each of the roles
          and how they are allocated within Payfixy Limited are given in a
          separate document Payfixy Limited IMS0502 Roles, Responsibilities and
          Authorities.
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          The Integrated Management System Manager shall have overall authority
          and responsibility for the implementation and management of the
          Management Systems, specifically:
        </p>

        <div class="px-4">
          <ul>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              ● The identification, documentation and fulfilment of applicable
              requirements
            </li>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              ● Implementation, management and improvement of risk management
              processes
            </li>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              ● Integration of processes
            </li>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              ● Compliance with statutory, regulatory and contractual
              requirements in the management of assets used to deliver products
              and services
            </li>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              ● Reporting to top management on performance and improvement
            </li>
          </ul>
        </div>
      </div>

      <div>
        <p class="font-black text-[19px] text-[#B01E31]">
          2.7 Continual Improvement Policy
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          Payfixy Limited policy with regard to Continual Improvement is to:
        </p>

        <div class="px-4">
          <ul>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              ● Continually improve the effectiveness of the IMS across all
              areas within scope.
            </li>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              ● Enhance current processes to bring them into line with good
              practice as defined within ISO/IEC 27001:2013 and ISO/IEC 22301.
            </li>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              ● Achieve certification to the management systems and maintain
              them on an ongoing basis
            </li>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              ● Increase the level of proactivity (and the stakeholder
              perception of proactivity) with regard to the ongoing management
              of the IMS.
            </li>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              ● Make processes and controls more measurable in order to provide
              a sound basis for informed decisions.
            </li>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              ● Achieve an enhanced understanding of and relationship with the
              business units to which the IMS applies
            </li>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              ● Review relevant metrics on an annual basis to assess whether it
              is appropriate to change them, based on collected historical data
            </li>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              ● Obtain ideas for improvement via regular meetings with
              stakeholders and document them in a Continual Improvement Log
            </li>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              ● Review the Continual Improvement Log at regular management
              meetings in order to prioritise and assess timescales and benefits
            </li>
          </ul>
        </div>

        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          Ideas for improvements may be obtained from any source including
          employees, customers, suppliers, IT staff, risk assessments and
          service reports. Once identified they will be added to the Payfixy
          Limited IMS1003 Continual Improvement Log and evaluated by the IMS
          Manager.
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          As part of the evaluation of proposed improvements, the following
          criteria will be used:
        </p>

        <div class="px-4">
          <ul>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              ● Threats
            </li>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              ● Vulnerabilities
            </li>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              ● Impact and likelihood before risk treatment
            </li>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              ● Risk Treatment (e.g., reduction, removal, transfer)
            </li>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              ● Impact and Likelihood after risk treatment
            </li>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              ● Function responsible/Owner
            </li>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              ● Timescale and Review Frequency
            </li>
          </ul>
        </div>

        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          Risk management will take place at several levels within the IMS,
          including:
        </p>

        <div class="px-4">
          <ul>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              ● Management planning – risks to the achievement of objectives
            </li>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              ● Information security and business continuity risk assessments
            </li>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              ● Assessment of the risk of changes via the change management
              process
            </li>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              ● At the project level as part of the management of significant
              business change
            </li>
          </ul>
        </div>

        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          High-level risk assessments will be reviewed on an annual basis or
          upon significant change to the business or service provision. For more
          detail on the approach to risk assessment please review the documents
          “Payfixy Limited IMS0603 Information Security Risk Assessment and
          Treatment Process”.
        </p>
      </div>

      <div>
        <p class="font-black text-[19px] text-[#B01E31]">2.9 Human Resources</p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          Payfixy Limited will ensure that all staff involved in IMS are
          competent on the basis of appropriate education, training, skills and
          experience.
        </p>

        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          The skills required will be determined and reviewed on a regular basis
          together with an assessment of existing skill levels within Payfixy
          Limited. Training needs will be identified, and a plan maintained to
          ensure that the necessary competencies are in place.
        </p>

        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          Training, education and other relevant records will be kept by the
          Human Resources Department to document individual skill levels
          attained.
        </p>
      </div>

      <div>
        <p class="font-black text-[19px] text-[#B01E31]">
          2.10 Auditing and Review
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          Once in place, it is vital that regular reviews take place of how well
          the IMS processes and procedures are being adhered to. This will
          happen at three levels:
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          1. Structured regular management review of conformity to policies and
          procedures
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          2. Internal audit reviews against the management system standards by
          the Payfixy Limited Audit Team
        </p>

        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          3. External audit against the standards in order to gain and maintain
          certification
        </p>

        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          Details of how internal audits will be carried out can be found in
          Payfixy Limited IMS0903 Internal Audit Procedure.
        </p>
      </div>

      <div>
        <p class="font-black text-[19px] text-[#B01E31]">
          2.11 Documentation Structure and Policy
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          All policies and plans that form part of the IMS must be documented.
          This section sets out the main documents that must be maintained in
          each area.
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          Details of documentation conventions and standards are given in the
          Payfixy Limited IMS0702 Procedure for the Control of Documents and
          Records.
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          A number of core documents have been created and will be maintained as
          part of the IMS. They are uniquely numbered, and the current versions
          are tracked in Payfixy Limited IMS0703 Documentation Log.
        </p>
      </div>

      <div>
        <p class="font-black text-[19px] text-[#B01E31]">
          2.12 Control of Records
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold">
          The keeping of records is a fundamental part of the IMS. Records are
          key information resources and represent evidence that processes are
          being carried out effectively.
        </p>

        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          The controls in place to manage records are defined in the document
          Payfixy Limited IMS0702 Procedure for the Control of Documents and
          Records.
        </p>
      </div>

      <div class="mb-6">
        <p class="font-black text-[19px] text-[#B01E31]">
          2.13 Consequence Management
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          Employees, suppliers, or other stakeholders who observe any deviations
          from the guidelines of this Policy, may report the fact to the ISMS or
          BCMS Manager via email at
          <span class="font-bold">info@payfixy.com</span> and may identify
          themselves or not.
        </p>

        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          Internally, the failure to comply with the guidelines of this Policy
          envisages the application of measures to charge the stakeholders who
          do not comply with this Policy according to the related seriousness of
          such non-compliance.
        </p>
      </div>
      <div class="mt-20"></div>
    </div>
  </div>
</section>
