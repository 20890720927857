<section>
  <div
    class="relative flex flex-col mt-10 md:mt-20 md:flex-row items-center mx-auto space-y-0"
  >
    <!-- left item -->
    <div class="flex flex-col container mx-auto px-6 space-y-6">
      <h2
        class="max-width-m font-extrabold text-3xl text-left md:text-5xl md:font-black font-sanMedium"
      >
        Flexible Pricing Plans<br />
        for Every Business Size
      </h2>
      <p class="text-left md:text-[16px] text-[18px] font-semibold">
        Whether you’re a small business, medium-sized enterprise, or large
        organisation, <br />
        we offer pricing plans that ensure the best value for your money. Our
        pricing is both<br />
        affordable and transparent.
      </p>

      <div class="block px-0 mb-5 md:hidden">
        <img src="assets/images/pricing-small.jpeg" class="w-full" alt="" />
      </div>
      <div class="trans">
        <p class="my-5">LOCAL TRANSACTION</p>
      </div>
      <div class="block md:flex">
        <div class="bg-[#FFDEC1] rounded-lg px-10 py-6 md:mr-10">
          <p class="text-[20px]">We offer</p>
          <p class="text-[25px] font-black font-sanMedium">
            Competitive Pricing
          </p>
        </div>
        <!-- <div class="mt-10 bg-[#FFDEC1] rounded-lg px-14 py-6 md:mt-0">
                    <p class="font-black text-[25px]">Transaction under</p>
                    <p class="font-black text-4xl text-[#B11E31]">NGN2500</p>
                    <p>We waive the <span class="font-black">NGN50</span> fee</p>
                </div> -->
      </div>
      <!-- <p class="text-left md:text-[16px] text-[18px] font-semibold">
                We offer competitive pricing for local transactions to help you save on costs.<br>
                With a flat rate of <span class="font-black">1.25%</span> and <span class="font-black">NGN 50</span> per transaction, our pricing is both<br>
                affordable and transparent.
            </p>
            <p class="text-left md:text-[16px] text-[18px] font-semibold">
                If your transaction is under <span class="font-black">N2500</span>, we waive the <span class="font-bold">N50 fee</span>, so you can enjoy<br> even greater savings.
                Additionally, our local transaction costs are capped at<br/><span class="font-black">N2500</span>, ensuring that this amount is the absolute
                most you will ever pay for<br> a single transaction.
            </p> -->
      <div class="getStarted pt-5">
        <button
          (click)="sales()"
          class="hidden px-6 p-2 font-bold text-faintBtnText text-xs bg-faintBtn rounded-md baseline hove:bg-brightRedLight md:block"
        >
          Contact Sales Team
        </button>
      </div>
    </div>
    <img
      src="assets/images/pricing_bg.png"
      class="hidden absolute z-[-1] right-0 h-[50rem] md:block"
      alt=""
    />
  </div>
</section>

<div class="flex justify-center text-center mt-20 bg-[#b01e31] py-20">
  <div>
    <div class="mt-5 text-3xl text-white md:font-black md:text-5xl">
      Get your business started with simplified <br />
      digital payment today!
    </div>
    <div class="flex justify-center mt-10 mb-10">
      <button
        class="px-6 p-2 font-bold text-[#b01e31] text-xs bg-white rounded-md baseline hove:bg-brightRedLight md:block"
      >
        Get Started
      </button>
    </div>
  </div>
</div>
