<section class="relative mb-20">
  <img
    src="assets/images/payX.png"
    alt=""
    class="hidden md:z-[-1] xl:h-[35rem] md:h-[25rem] md:absolute md:block"
  />
  <div
    class="container flex flex-col justify-end md:flex-row mx-auto space-y-0"
  >
    <div class="md:w-1/2 px-6 mt-2 md:px-0 md:mt-0">
      <h2
        class="text-3xl text-brightRed md:text-1xl md:font-light text-left md:mt-20"
      >
        PAYMENT GATEWAY
      </h2>
      <p
        class="text-left text-5xl mt-5 font-extrabold md:text-left md:font-black"
      >
        Fast, Easy, and<br />
        Reliable
      </p>

      <p
        class="md:text-[16px] text-[18px] md:font-bold font-semibold mt-5 text-[#241023]"
      >
        Upgrade your payment experience with Payfixy's simple<br />
        and secure gateway
      </p>

      <p
        class="md:text-[16px] text-[18px] md:font-bold font-semibold mt-5 text-[#241023] text-wrap"
      >
        With Payfixy's payment gateway, you can offer your customers<br />
        a wide range of payment options, which will ultimately increase your
        conversion rate.
      </p>

      <div class="getStarted mt-5 mb-10 md:mb-0">
        <button
          (click)="sales()"
          class="px-6 p-2 font-bold bg-brightRed text-white text-xs rounded-md baseline md:block md:bg-faintBtn md:text-brightRed"
        >
          Get Started
        </button>
      </div>
    </div>
    <div>
      <img
        src="assets/images/payment-sm.png"
        alt=""
        class="block width-full md:hidden"
      />
    </div>
  </div>
</section>

<section>
  <div class="donation">
    <div
      class="container text-left mx-auto mt-20 flex-col px-6 md:pt-10 space-y-6 md:mt-0"
    >
      <!-- <div class="text-brightRed font-light">PAYX</div> -->
      <h2 class="text-2xl md:text-3xl text-brightRed md:font-light md:mt-20">
        PAYX
      </h2>
      <h2 class="font-black text-5xl md:text-5xl md:font-black">
        Create Smart Campaigns<br />
        to Receive Donations<br />
        Seamlessly with Pay-X
      </h2>
      <p class="md:text-[16px] text-[20px] md:font-bold font-extrabold">
        With Pay-X, you can easily create smart campaigns to receive
        donations<br />
        from supporters all around the world. Our intuitive and user-friendly
        interface<br />
        ensures that the donation process is seamless and hassle-free, helping
        you<br />
        to raise funds quickly and efficiently.
      </p>
      <div class="getStarted mt-10">
        <button
          (click)="sales()"
          class="hidden px-6 p-2 font-bold text-faintBtnText text-xs bg-faintBtn rounded-md baseline hove:bg-brightRedLight md:block"
        >
          Contact Sales
        </button>
      </div>
    </div>
    <div class="mt-10">
      <img
        src="assets/images/small-phone.png"
        alt=""
        class="block width-full md:hidden"
      />
    </div>
  </div>
</section>

<section id="tranXact"></section>

<section class="relative">
  <div
    class="flex flex-col-reverse md:flex-row mx-auto mt-[21rem] space-y-0 md:mt-[10rem]"
  >
    <img
      src="assets/images/TransaX.png"
      alt=""
      class="hidden md:z-[-1] md:h-[33rem] md:absolute md:block"
    />

    <!-- <img src="assets/images/TransaX.png" alt=""> -->
    <div
      class="container flex flex-col justify-end md:flex-row mx-auto space-y-0"
    >
      <div class="md:w-1/2 px-6 mt-20 md:px-0 md:mt-0">
        <h2 class="text-2xl text-brightRed md:text-3xl md:font-light md:mt-20">
          TRANXACT
        </h2>
        <p class="text-5xl mt-5 font-extrabold md:font-black">
          Start selling online<br />
          effortlessly, even<br />
          without a website!
        </p>

        <p
          class="hidden md:block md:text-[16px] text-[18px] md:font-bold font-semibold mt-5 text-[#241023] md:text-left"
        >
          Generate payment links to receive money online and take your<br />
          business to the next level. With TranXact, you can sell anywhere,<br />
          whether it’s in-store, online, or on-the-go. TransXact helps you<br />
          receive instant payments when you sell your products and services
        </p>

        <p
          class="block md:hidden md:text-[16px] text-[18px] md:font-bold font-semibold mt-5 text-[#241023] md:text-left"
        >
          Generate payment links to receive money online and take your business
          to the next level. With TranXact, you can sell anywhere, whether it’s
          in-store, online, or on-the-go. TransXact helps you receive instant
          payments when you sell your products and services
        </p>

        <div class="getStarted mt-5 mb-10 md:mb-0">
          <button
            (click)="sales()"
            class="px-6 p-2 font-bold bg-brightRed text-white text-xs rounded-md baseline md:block md:bg-faintBtn md:text-brightRed"
          >
            Get Started
          </button>
        </div>
      </div>
      <div>
        <img
          src="assets/images/market.png"
          alt=""
          class="block width-full md:hidden"
        />
      </div>
    </div>
  </div>
</section>

<section>
  <div
    class="flex container justify-center rounded-lg bg-[#f7f7f7] mt-20 md:mt-36 mx-auto"
  >
    <div class="pt-10">
      <div
        class="text-2xl font-black text-center md:text-3xl md:font-black text-[#b11e31]"
      >
        Get started in 3 simple steps:
      </div>
      <div
        class="flex text-center items-center flex-col md:flex-row space-x-0 px-10 my-16 md:space-x-16"
      >
        <div class="flex items-center md:justify-center">
          <div class="bg-[#95D7FF] relative pt-10 w-48 h-48 rounded-full">
            <div
              id="step-1"
              class="bg-brightRed p-2 w-10 h-10 text-white rounded-full"
            >
              <div>01</div>
            </div>
            <div class="flex text-center justify-center">
              <img src="assets/images/signup.svg" alt="" />
            </div>
            <div class="text-sm text-[#B11E31]">
              Sign Up for Free account<br />
              in minutes
            </div>
          </div>
        </div>

        <div class="mt-20 md:mt-0">
          <div class="bg-[#FDEFB3] relative pt-10 w-48 h-48 rounded-full">
            <div
              id="step-1"
              class="bg-brightRed p-2 w-10 h-10 text-white rounded-full"
            >
              <div>02</div>
            </div>
            <div class="flex text-center justify-center">
              <img src="assets/images/verified.svg" alt="" />
            </div>
            <div class="text-sm text-[#B11E31]">Complete KYC</div>
          </div>
        </div>

        <div class="mt-20 md:mt-0">
          <div class="bg-[#CED5E9] relative pt-10 w-48 h-48 rounded-full">
            <div
              id="step-1"
              class="bg-brightRed p-2 w-10 h-10 text-white rounded-full"
            >
              <div>03</div>
            </div>
            <div class="flex text-center justify-center">
              <img src="assets/images/product.svg" alt="" />
            </div>
            <div class="text-sm text-[#B11E31]">
              Start enjoying<br />
              our product
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="flex justify-center text-center mt-20 bg-[#b01e31] py-20">
  <div>
    <div class="mt-5 text-2xl text-white md:font-black md:text-4xl">
      Get your business started with simplified <br />
      digital payment today!
    </div>
    <div class="flex justify-center mt-10 mb-10">
      <button
        (click)="sales()"
        class="px-6 p-2 font-bold text-[#b01e31] text-xs bg-white rounded-md baseline hove:bg-brightRedLight md:block"
      >
        Get Started
      </button>
    </div>
  </div>
</div>
