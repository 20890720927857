import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-privacy',
  templateUrl: './ims-policy.component.html',
  styleUrls: ['./ims-policy.component.scss']
})
export class IMSpolicyComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  
  }


  sales(){
    this.router.navigate(['contact-us'])
  }

}
