<div class="flex flex-wrap bg-white">
  <div class="w-full">
    <nav class="container px-6 relative mx-auto p-3">
      <div class="flex item-center justify-between py-2">
        <!-- logo-->
        <div routerLink="home" class="pt-2 font-bold text-2xl cursor-pointer">
          <img
            src="assets/images/payfixy.svg"
            width="70%"
            height="auto"
            alt=""
          />
        </div>
        <!-- menu items -->
        <div class="hidden mt-2 space-x-6 md:flex">
          <a
            class="group menuDropDown product-menu relative hover:text-black cursor-pointer"
          >
            <div class="flex">
              <span class="mr-2"> Product </span>
              <span id="arrowDown" class="mt-1">
                <img src="assets/images/dropDown.png" width="60%" alt="" />
              </span>
              <span id="arrowUp" class="mt-1">
                <img src="assets/images/arrow-up.png" width="60%" alt="" />
              </span>
            </div>
            <div
              id="menu1"
              class="group-hover:block dropdown-menu absolute hidden h-auto"
            >
              <ul class="top-0 w-48 bg-white shadow px-4 py-6">
                <li routerLink="payment" class="py-1 dropMenu">
                  <div class="block text-base text-sm p-1 cursor-pointer">
                    Payment Gateway
                  </div>
                </li>
                <li class="py-1 dropMenu">
                  <a
                    [routerLink]="['payX']"
                    fragment="payXsection"
                    class="block text-base text-sm p-1 cursor-pointer"
                    >PayX</a
                  >
                </li>
                <li class="py-1 dropMenu">
                  <a
                    [routerLink]="['payment']"
                    fragment="tranXact"
                    class="block text-base text-sm p-1 cursor-pointer"
                    >TraXact</a
                  >
                </li>
              </ul>
            </div>
          </a>
          <a
            routerLink="developer"
            class="text-darkGrayishBlue hover:text-black"
            >Developer</a
          >
          <a routerLink="pricing" class="text-darkGrayishBlue hover:text-black"
            >Pricing</a
          >
          <a
            class="group menuDropDown pafixy-menu relative hover:text-black cursor-pointer"
          >
            <div class="flex">
              <span class="mr-2"> Pafixy </span>
              <span id="menuArrowDown" class="mt-1">
                <img src="assets/images/dropDown.png" width="60%" alt="" />
              </span>
              <span id="menuArrowUp" class="mt-1">
                <img src="assets/images/arrow-up.png" width="60%" alt="" />
              </span>
            </div>
            <div
              id="menu2"
              class="group-hover:block dropdown-menu absolute hidden h-auto"
            >
              <ul class="top-0 w-48 bg-white shadow px-4 py-6">
                <li routerLink="about-us" class="py-1 dropMenu">
                  <a class="block text-base text-sm p-1 cursor-pointer"
                    >About Pafixy</a
                  >
                </li>
                <li routerLink="contact-us" class="py-1 dropMenu">
                  <a class="block text-base text-sm p-1 cursor-pointer"
                    >Contact Us</a
                  >
                </li>
              </ul>
            </div>
          </a>
        </div>
        <!-- button -->
        <button
          (click)="sales()"
          class="hidden px-6 text-white text-xs bg-brightRed rounded-md baseline hove:bg-brightRedLight md:block"
        >
          Get Started
        </button>

        <button
          (click)="toggleSideBar()"
          id="menu-btn"
          class="block hamburger focus:outline-none md:hidden"
        >
          <img src="assets/images/hamburger.png" alt="" />
        </button>
      </div>

      <!-- mobile menu -->
      <div class="sidebar">
        <div class="flex justify-between p-6">
          <div (click)="removeSideBar()" routerLink="home">
            <img src="assets/images/logo_White.png" alt="" />
          </div>
          <div>
            <img
              (click)="removeSideBar()"
              src="assets/images/cancel.png"
              alt=""
            />
          </div>
        </div>
        <hr class="line" />

        <div class="menu-items mt-5 px-6">
          <div>
            <div class="text-white font-extrabold text-[20px] cursor-pointer">
              Product
            </div>
            <div
              (click)="removeSideBar()"
              routerLink="payment"
              class="text-white text-[18px] mt-2 cursor-pointer"
            >
              Payment Gateway
            </div>
            <div
              (click)="removeSideBar()"
              class="text-white text-[18px] mt-2 cursor-pointer"
            >
              <a
                [routerLink]="['payX']"
                fragment="payXsection"
                class="block text-base text-sm p-1 cursor-pointer"
                >PayX</a
              >
            </div>
            <div
              (click)="removeSideBar()"
              [routerLink]="['payment']"
              fragment="tranXact"
              class="text-white text-[18px] mt-2 cursor-pointer"
            >
              TranXact
            </div>
          </div>
        </div>
        <!-- <hr class="line"> -->
        <div class="p-2 border-t px-6 mt-5">
          <div
            (click)="removeSideBar()"
            routerLink="developer"
            class="text-white font-extrabold text-[20px] cursor-pointer"
          >
            Developer
          </div>
        </div>
        <hr class="line" />
        <div class="p-2 px-6">
          <div
            (click)="removeSideBar()"
            routerLink="pricing"
            class="text-white font-extrabold text-[20px] cursor-pointer"
          >
            Pricing
          </div>
        </div>
        <hr class="line" />
        <div class="px-6 mt-5">
          <div class="text-white font-extrabold text-[20px] cursor-pointer">
            Pafixy
          </div>
          <div
            (click)="removeSideBar()"
            routerLink="about-us"
            class="text-white text-[18px] mt-2 cursor-pointer"
          >
            About Pafixy
          </div>
          <div
            (click)="removeSideBar()"
            routerLink="contact-us"
            class="text-white text-[18px] mt-2 cursor-pointer"
          >
            Contact Us
          </div>
        </div>
      </div>
    </nav>
    <!-- <hr> -->
  </div>
</div>
