<div
  *ngIf="showBanner"
  class="fixed bottom-6 left-10 right-10 bg-[#B01E31] rounded-md text-white p-4"
>
  <div class="max-w-screen">
    <!-- <p>
            We use cookies to ensure you get the best experience on our website.
            <a class="underline" href="#">Learn more</a>
        </p> -->
    <div class="block md:flex justify-between mx-[7%]">
      <div class="text-[15px]">
        By clicking Accept, you agree to the storing of cookies on your device
        to enhance site navigation,<br />
        analyse site usage, and assist in our marketing efforts. View our
        Privacy Policy for more information.
      </div>
      <div class="mt-2 flex justify-right">
        <div class="mt-2">
            <a
              class="mr-5 text-[#FFFFFF] border-b pb-1"
              (click)="toggleModal()"
              >Preferences</a
            >
        </div>
        <button
          class="px-4 py-2 bg-[#FFDEEB] text-[#B11E31] rounded"
          (click)="acceptCookies()"
        >
          Accept
        </button>
      </div>
    </div>
  </div>
</div>


<!-- cookie modal -->
<div
  class="fixed inset-0 flex items-center justify-center z-[1] bg-black bg-opacity-50 sm:mx-6 lg:mx-0 modal-body"
  *ngIf="showModal"
>
<div class="modal-content">
    <div class="bg-white rounded-lg relative p-8 w-[18rem] md:w-[40rem] max-h-full">
        <div (click)="toggleModal()" class="absolute flex right-[-10px] cursor-pointer top-[-8px]">
            <img src="assets/images/close.png" alt="">
        </div>
  
      <div
        class="flex gap-x-4 items-center cursor-pointer sm:text-heading-xs text-body-2xl font-extrabold text-[#0D2359] mb-4"
      >
        Privacy Preference Center
      </div>
      <p class="text-black sm:text-body-lg text-body-md mb-8">
        When you visit websites, they may store or retrieve data in your browser.
        This storage is often necessary for the basic functionality of the
        website. The storage may be used for marketing, analytics, and
        personalization of the site, such as storing your preferences. Privacy is
        important to us, so you have the option of disabling certain types of
        storage that may not be necessary for the basic functioning of the
        website. Blocking categories may impact your experience on the website.
      </p>
      <div class="sm:flex sm:justify-start justify-between gap-x-4 mb-12">
        <button
        (click)="declineCookies()" class="sm:w-auto w-full rounded-md px-5 py-3 border-[2px] border-brightRed text-brightRed font-extrabold flex items-center gap-x-2 sm:justify-left justify-between sm:mb-0 mb-4"
        >
          Reject all cookies
        </button>
        <button
          (click)="acceptCookies()" class="sm:w-auto w-full rounded-md px-5 py-3 bg-brightRed text-white flex items-center gap-x-2 sm:justify-left justify-between"
        >
          Allow all cookies
        </button>
      </div>
  
      <div
        class="flex gap-x-4 items-center cursor-pointer sm:text-heading-xs text-body-2xl font-black text-[#0D2359] mb-4"
      >
        Manage Consent Preferences by Category
      </div>
  
      <div class="mb-5 border-b border-b-[1px] pb-3 border-b-[#dddde6]">
          <div class="flex justify-between mb-2">
            <div>
              <p
              class="sm:text-body-lg text-body-md font-black text-brightRed"
            >
              Essential
            </p>
            </div>
            <div class="font-extrabold">
              Always Active
            </div>
          </div>
          <p class="text-black sm:text-body-lg text-body-md">
            These items are required to enable basic website functionality.
          </p>
        </div>
      <div class="mb-5 border-b border-b-[1px] pb-3 border-b-[#dddde6]">
        <div class="flex justify-between mb-2">
          <p
            class="sm:text-body-lg text-body-md font-black text-brightRed"
          >
            Marketing
          </p>
        </div>
        <p class="text-black sm:text-body-lg text-body-md">
          These items are used to deliver advertising that is more relevant to you
          and your interests. They may also be used to limit the number of times
          you see an advertisement and measure the effectiveness of advertising
          campaigns. Advertising networks usually place them with the website
          operator’s permission.
        </p>
      </div>
      <div class="mb-5 border-b border-b-[1px] pb-3 border-b-[#dddde6]">
        <div class="flex justify-between mb-2">
          <p
            class="sm:text-body-lg text-body-md font-black text-brightRed"
          >
            Personalization
          </p>
        </div>
        <p class="text-black sm:text-body-lg text-body-md">
          These items allow the website to remember choices you make (such as your
          user name, language, or the region you are in) and provide enhanced,
          more personal features. For example, a website may provide you with
          local weather reports or traffic news by storing data about your current
          location.
        </p>
      </div>
  
      <div class="mb-5 border-b border-b-[1px] pb-3 border-b-[#dddde6]">
          <div class="flex justify-between mb-2">
            <p
              class="sm:text-body-lg text-body-md font-black text-brightRed"
            >
            Analytics
            </p>
          </div>
          <p class="text-black sm:text-body-lg text-body-md">
              These items help the website operator understand how its website performs, 
              how visitors interact with the site, and whether there may be technical issues.
               This storage type usually doesn’t collect information that identifies a visitor.
          </p>
        </div>
    </div>
  </div>
</div>

