<section>
  <div
    class="relative flex flex-col mt-10 md:mt-20 md:flex-row items-center mx-auto space-y-0"
  >
    <!-- left item -->
    <div class="flex flex-col container mx-auto px-6 space-y-6">
      <h2
        class="max-width-m font-extrabold text-3xl text-left md:text-5xl md:font-black font-sanMedium text-[#B01E31]"
      >
        Privacy Policy
      </h2>
      <p class="text-[25px] font-black font-sanMedium">
        Date of Privacy Notice:
      </p>

      <p class="text-left md:text-[16px] text-[18px] font-semibold">
        August 28, 2023
      </p>

      <p class="text-[25px] font-black font-sanMedium">Our contact details:</p>
      <div>
        <p class="font-black text-[19px]">Name:</p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold">
          PAYFIXY LIMITED
        </p>
      </div>
      <div>
        <p class="font-black text-[19px]">Address:</p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold">
          Plot 1014 Fria Close, Wuse II, Abuja-FCT, Nigeria 39A Fola Osibo Road,
          Lekki Phase 1, Lagos, Nigeria
        </p>
      </div>
      <div>
        <p class="font-black text-[19px]">Phone Number:</p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold">
          +234 903 229 3077
        </p>
      </div>
      <div>
        <p class="font-black text-[19px]">Website:</p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold">
          <a href="https://payfixy.com" target="_blank">https://payfixy.com</a>
        </p>
      </div>
      <div>
        <p class="font-black text-[19px]">E-mail:</p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold">
          info@payfixy.com
        </p>
      </div>

      <div>
        <p class="font-black text-[19px] text-[#B01E31]">1. Purpose</p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold">
          This website is operated by
          <span class="font-black font-sanMedium">PAYFIXY LIMITED</span> and
          whose registered address is Plot 1014 Fria Close, Wuse II, Abuja-FCT
          Nigeria. We are committed to protecting and preserving the privacy of
          our visitors when visiting our site or communicating electronically
          with us. This policy sets out how we process any personal data we
          collect from you or that you provide to us through our website. We
          confirm that we will keep your information secure and that we will
          comply fully with all applicable Nigerian Data Protection legislation
          and regulations. It will also help you to identify what rights you
          have as data subject and who to contact for more information, to
          exercise your rights or to make a complaint. Please read the following
          carefully to understand what happens to personal data that you choose
          to provide to us, or that we collect from you when you visit this
          site. By visiting www.payfixy.com (our website) you are accepting and
          consenting to the practices described in this policy.
        </p>
      </div>
      <div>
        <p class="font-black text-[19px] text-[#B01E31]">
          2. Data Protection Officer
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold">
          It is part of our obligation as a data controller under the Nigeria
          Data Protection Regulation to designate a Data Protection Officer
          (DPO) who will be responsible for data protection compliance issues.
          Contact our DPO if you have any questions or concerns about our
          personal data policies or practices. If you would like to exercise
          your privacy rights, please direct your query to our Data Protection
          Officer at:
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-3">
          “The Data Protection Officer” <br />
          Payfixy Limited <br />Plot 1014 Fria Close, Wuse II, Abuja-FCT,
          Nigeria. <br />ndpr@maggrouplimited.com
        </p>
      </div>

      <div>
        <p class="font-black text-[19px] text-[#B01E31]">
          3. The type of personal information we collect
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          We may collect, store, and use the following kinds of personal
          information about individuals who visit and use our website:
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold my-6">
          <span class="font-black font-sanMedium">
            Information you supply to us.</span
          >
          You may supply us with information about you by filling in forms on
          our website. This includes information you provide when you submit
          products and services registration forms in registering to use any of
          our services, a contact/enquiry form, websites, products, and business
          applications used for our services (Payfixy Services).
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold">
          <span class="font-black font-sanMedium">
            Information our website automatically collects about you.</span
          >
          With each of your visits to our website we may automatically collect
          information including the following:
        </p>
      </div>
      <div class="px-4">
        <ul>
          <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
            <span class="font-black font-sanMedium"
              >Technical information,</span
            >
            including a truncated and anonymised version of your Internet
            protocol (IP) address, browser type and version, operating system
            and platform;
          </li>
          <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
            <span class="font-black font-sanMedium">
              Information about your visit,</span
            >
            including what pages you visit, how long you are on the site, how
            you got to the site (including date and time); page response times,
            length of visit, what you click on, documents downloaded and
            download errors.
          </li>
        </ul>
      </div>
      <div>
        <p class="font-black text-[19px] text-[#B01E31]">
          4. How we get the personal information and why we have it
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold">
          <span class="font-black font-sanMedium"
            >Information you supply to us.
          </span>
          We will use this information:
        </p>
        <div class="px-4">
          <ul>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              o To provide you with information and/or services that you request
              from us;
            </li>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              o To process your payment transactions, including payment
              authorization, chargebacks, and other dispute resolution.
            </li>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              o To verify your identity and the information that you provide to
              us for you to use our services, in accordance with statutory
              requirements on Know Your Customer (KYC), Anti-Money Laundering,
              Combating the Financing of Terrorism and Countering the
              Proliferation Financing of Weapons of Mass Destruction.
            </li>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              o To comply with our legal obligations and assist regulatory,
              government and law enforcement agencies.
            </li>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              o Customer Service: To respond to your enquiry about our service
              offerings, including requests for help or to resolve disputes.
            </li>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              o To design and price our Product and Services o To identify and
              investigate illegal activities.
            </li>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              o To review and process your job application if you apply for a
              job with us.
            </li>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              o We may share this information with our social media,
              advertising, affiliates, and analytics partners.
            </li>
          </ul>
        </div>

        <p class="text-left md:text-[16px] text-[18px] font-semibold my-8">
          Under the Nigerian Data Protection Act, the lawful bases we rely on
          for processing your information are:
        </p>
        <div class="px-4">
          <ul>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              a. Your consent. You can withdraw your consent at any time by
              contacting ndpr@maggrouplimited.com
            </li>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              b. Contractual business obligation: We are obligated to manage
              your personal data in line with best practices. We protect your
              personal data using physical, technical, and administrative
              security measures to reduce the risks of loss, misuse,
              unauthorised access, disclosure, and alteration. We also utilize
              industry recommended security protocols to safeguard your personal
              data.
            </li>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              c. Legal obligation: At Payfixy, our commitment to both our users
              and regulatory compliance is paramount. As a result, we bear
              certain legal responsibilities that necessitate the processing of
              the personal data you provide to us. This processing primarily
              centers around verifying the accuracy of user information and
              ensuring the seamless execution of payment transactions. We
              collect essential details such as card information and transaction
              IDs, which are pivotal for processing payments securely and
              effectively.
            </li>
          </ul>
          <p class="text-left md:text-[16px] text-[18px] font-semibold my-5">
            Our obligations extend beyond the periphery of mere payment
            processing. We are also mandated by laws pertaining to Anti-Money
            Laundering, Combating the Financing of Terrorism & Countering the
            Proliferation Financing of Weapons of Mass Destruction
            (AML/CFT/CPF), Know-Your-Customer (KYC) regulations, and the
            prevention of fraudulent activities. To fulfill these obligations,
            it's imperative for us to have access to pertinent personal data.
            This data, encompassing critical information like card details and
            transaction identifiers, is integral for corroborating the
            authenticity of transactions, preventing unlawful financial
            activities, and safeguarding the interests of all parties involved.
            It is important to note that in certain circumstances, we may be
            required to share relevant data with third parties. These instances
            arise when strict legal compliance mandates us to collaborate with
            external entities to ensure adherence to anti-fraud, AML, and KYC
            regulations. Such data sharing is conducted with the utmost caution,
            prioritizing data security and compliance at all times.
          </p>
          <p class="text-left md:text-[16px] text-[18px] font-semibold my-5">
            In our commitment to maintaining the integrity of the payment
            ecosystem, we undertake vigilant monitoring of payment transaction
            patterns. By analyzing these patterns, we derive invaluable insights
            to proactively mitigate risks associated with fraudulent activities,
            money laundering, terrorist financing and any other detrimental
            actions that could potentially impact Payfixy, our esteemed End
            Users, and their valued
          </p>
          <p class="text-left md:text-[16px] text-[18px] font-semibold my-5">
            End Customers. In essence, our data processing practices are
            intricately interwoven with our duty to uphold both the law and the
            trust our users place in us. By meticulously handling personal data,
            conducting thorough verifications, and collaborating responsibly
            with relevant authorities and third parties, we not only facilitate
            secure transactions but also contribute to a safer and more
            transparent financial landscape for all stakeholders involved.
          </p>
          <p class="text-left md:text-[16px] text-[18px] font-semibold my-5">
            d. Legitimate interests: The data we collect depends on the
            relationship that you have with us, either as an end user, or
            merchant. We may use your data for the following purposes:
          </p>

          <div class="px-4">
            <ul>
              <li
                class="text-left md:text-[16px] text-[18px] font-semibold my-4"
              >
                o To verify your identity, while ensuring efficiency and
                information security.
              </li>
              <li
                class="text-left md:text-[16px] text-[18px] font-semibold my-4"
              >
                o Detect, monitor and prevent fraud and unauthorized payment
                transactions;
              </li>
              <li
                class="text-left md:text-[16px] text-[18px] font-semibold my-4"
              >
                o To effectively execute payment transactions, prevent
                fraudulent activities, and ensure compliance with regulatory
                requirements.
              </li>
              <li
                class="text-left md:text-[16px] text-[18px] font-semibold my-4"
              >
                o To analyze, modify and improve our services.
              </li>
              <li
                class="text-left md:text-[16px] text-[18px] font-semibold my-4"
              >
                o To ensure that your payments routed through our platform are
                legitimate and done with your proper authorization.
              </li>
              <li
                class="text-left md:text-[16px] text-[18px] font-semibold my-4"
              >
                o Your data may be shared with relevant parties involved in
                payment processing, including financial institutions, regulatory
                authorities, and law enforcement agencies, as required by law
                for the purpose of fraud prevention and compliance.
              </li>
              <li
                class="text-left md:text-[16px] text-[18px] font-semibold my-4"
              >
                o Any other purpose that we disclose to you while providing our
                services.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <p class="font-black text-[19px] text-[#B01E31]">
          5. Promotional messages
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          Payfixy may contact you to inform you of products or services that may
          be of interest to you. If you do not want to receive such promotional
          materials from us, you can opt out at any time by sending an email to:
          ndpr@maggrouplimited.com
        </p>
      </div>
      <div>
        <p class="font-black text-[19px] text-[#B01E31]">
          6. Information we automatically collect about you
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold">
          <span class="font-black font-sanMedium"
            >We will use this information:
          </span>
        </p>
        <div class="px-4">
          <ul>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              o To administer our site including troubleshooting and statistical
              purposes.
            </li>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              o to improve our site to ensure that content is presented in the
              most effective manner for you and for your computer.
            </li>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              o Security and debugging as part of our efforts to keep our site
              safe and secure. o fraud and money laundering checks.
            </li>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              o Fraud and money laundering checks.
            </li>
          </ul>
        </div>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          All automated processing of personal data is conducted on lawful
          basis. You can contact us to request that automated processing of your
          personal data be reviewed by a human being if you detect any
          inaccuracies in your personal data.
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          We use Google Analytics to track this information. Find out how Google
          uses your data at <br />
          <a
            href="https://support.google.com/analytics/answer/6004245"
            target="_blank"
            >https://support.google.com/analytics/answer/6004245</a
          >
          .
        </p>
      </div>

      <div>
        <p class="font-black text-[19px] text-[#B01E31]">7. Cookies</p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          Our website uses cookies to distinguish you from other users of our
          website. This helps us to provide you with a good experience when you
          browse our website and allows us to improve our site. For detailed
          information on the cookies, we use and the purposes for which we use
          them, see our Cookie Policy.
        </p>
      </div>

      <div>
        <p class="font-black text-[19px] text-[#B01E31]">
          8. Disclosure of your information
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          Any information you provide to us will either be emailed directly to
          us or may be stored on a secure server. We use a trusted third-party
          website and hosting provider to facilitate the running and management
          of this website.
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          We may share the information about you and your dealings with us, to
          the extent permitted by law, with our external auditors, banks,
          strategic partners / service providers, affiliates, regulators,
          government agencies, courts. We may also disclose your personal
          information for purposes of national security, law enforcement, or
          other issues of public importance. Your Personal information will not
          be shared with third parties for their marketing purposes. In the
          event of a restructuring, merger, or sale of Payfixy, we may transfer
          any and all personal information we collect to the relevant third
          party.
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          Our partners meet high data protection and security standards and are
          bound by contract to keep any information they process on our behalf
          confidential. Any data that may be collected through this website that
          our partners process, is kept secure and only processed in the manner
          we instruct them to on our behalf.
        </p>
      </div>

      <div>
        <p class="font-black text-[19px] text-[#B01E31]">
          9. Third party links
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          Our site may, from time to time, contain links to and from third-party
          websites. If you follow a link to any of these websites, please note
          that these websites have their own privacy policies and Payfixy does
          not accept any responsibility or liability for these policies. Payfixy
          will not be liable for any breach of confidentiality or privacy of
          your information on such sites. We encourage you to learn about the
          privacy practices of those third parties before you submit any
          personal data to those websites.
        </p>
      </div>

      <div>
        <p class="font-black text-[19px] text-[#B01E31]">
          10. Your rights – access to your personal data
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          You have the right to ensure that your personal data is being
          processed lawfully (“Subject Access Right”). Your subject access right
          can be exercised in accordance with data protection laws and
          regulations. Any subject access request must be made in writing to
          ndpr@maggrouplimited.com. We will provide your personal data to you
          within the statutory time frames. To enable us to trace any of your
          personal data that we may be holding, we may need to request further
          information from you. If you have a complaint about how we have used
          your information, you have the right to complain to the Nigeria Data
          Protection Commission (NDPC).
        </p>
      </div>

      <div>
        <p class="font-black text-[19px] text-[#B01E31]">
          11.How we store your personal information
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          Your information is securely stored and processed on our secure
          servers as well as secure physical locations and cloud infrastructure.
          The data that we collect from you may be transferred to or stored in
          cloud locations at globally accepted vendors' data center. We maintain
          administrative, technical, and physical security measures to protect
          the Personal Information you provide against unauthorized access, risk
          of loss, disclosure, copying, misuse or modification. Whenever your
          information is transferred to other locations, we will take all
          necessary steps to ensure that your data is handled securely and in
          accordance with this privacy policy.
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          We have put in place safeguards such firewalls and data encryption,
          physical access controls to our data centres, facilities, and
          information authorization controls.
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          We keep your personal information on our servers for as long as
          necessary for the purpose of providing our Services to you and to the
          extent needed to comply with legal and statutory obligations.
        </p>
      </div>

      <div>
        <p class="font-black text-[19px] text-[#B01E31]">
          12.Data security and retention
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          The security of your Personal Information is important to Payfixy
          Limited. Your information is securely stored on our servers with
          firewall, dataencryption and granting access only to employees in
          order to fulfil their job responsibilities. We maintain
          administrative, technical, and physical controls designed to protect
          the Personal Information you provide, or we collect against loss or
          theft, as well as against any unauthorized access, risk of loss,
          disclosure, copying, misuse, or modification.
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          Where we have provided you (or where you have chosen) a password which
          grants you access to specific areas on our site, you are responsible
          for keeping your password confidential.
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          We retain personal information on our servers for as long as is
          reasonably necessary for the purpose(s) that it was collected. Storage
          of your data is also determined by legal, regulatory, or operational
          requirements. We only retain information for compliance with legal and
          regulatory requests for certain data, meet business and audit
          requirements, respond to complaints and queries, or address disputes
          or claims that may arise.
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          We require all parties involved in data processing data on our behalf
          to comply with relevant laws, policies, and guidelines to ensure
          protection of personal information. We update and test our security
          technology on an ongoing basis. We restrict access to your personal
          data to those employees who need to know that information to provide
          benefits or services to you. In addition, we train our employees about
          the importance of responsibilities and the confidentiality of personal
          information.
        </p>
      </div>

      <div>
        <p class="font-black text-[19px] text-[#B01E31]">
          13.Data Subject Rights
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold">
          <span class="font-black font-sanMedium"
            >Under data protection law, you have rights including:
          </span>
        </p>
        <div class="px-4">
          <ul>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              o Your right of access - You have the right to ask us for copies
              of your personal data.
            </li>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              o Your right to rectification - You have the right to ask us to
              rectify personal information you think is inaccurate. You also
              have the right to ask us to complete information you think is
              incomplete.
            </li>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              o Your right to withdraw consent to processing where consent has
              previously been given. o Your right to erasure - You have the
              right to ask us to erase your personal data in certain
              circumstances. We might continue to retain our personal data if
              there are valid legal, regulatory, or operational reasons.
            </li>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              o Your right to restriction of processing - You have the right to
              ask us to restrict the processing of your personal data in certain
              circumstances.
            </li>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              o Your right to object to processing - You have the right to
              object to the processing of your personal data in certain
              circumstances. We might continue to process your data is there are
              valid legal or operational reasons.
            </li>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              o Your right to data portability - You have the right to ask that
              we transfer your personal data to a third party, or to you.
            </li>
            <li class="text-left md:text-[16px] text-[18px] font-semibold my-4">
              o Right to lodge a complaint with the Nigeria Data Protection
              Commission
            </li>
          </ul>
        </div>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          You are not required to pay any fee to exercise your rights. If you
          make a request, we have one month to respond to you.
        </p>
      </div>

      <div>
        <p class="font-black text-[19px] text-[#B01E31]">
          14.Changes to our privacy policy
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          This policy may be revised to reflect changes in the legal,
          regulatory, and operating environment. Any changes we may make to our
          privacy policy in the future will be posted on this page and, where
          appropriate, notified to you by e-mail. Such will become effective as
          at the time it is posted. We encourage you to check this page
          periodically for updates to this policy.
        </p>
      </div>

      <div class="mb-6">
        <p class="font-black text-[19px] text-[#B01E31]">
          15.Questions, concerns, or complaints
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          If you have questions, concerns, complaints, or would like to exercise
          your rights, please contact our Data Protection Officer through
          ndpr@maggrouplimited.com.
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          We will provide a response to your request within one month of the
          date of submission of the request.
        </p>
        <p class="text-left md:text-[16px] text-[18px] font-semibold mt-6">
          You can also complain to the Regulator if you are unsatisfied with our
          use of your data. You may contact:
        </p>

        <p
          class="text-left md:text-[16px] text-[18px] font-semibold mt-6 mb-12"
        >
          Nigeria Data Protection Commission <br />
          No. 5 Donau Crescent,<br />
          Off Amazon Street, Maitama, Abuja, Nigeria. <br />Email:
          info@ndpb.gov.ng <br />Tel: +234 (0) 916 061 5551 <br />
          NDPC website:
          <a href="https://ndpc.gov.ng" target="_blank">https://ndpc.gov.ng</a>
        </p>
      </div>
    </div>
  </div>
</section>
