<!-- Header session -->
<section>
  <div class="bg-white md:bg-brightRedLight">
    <!-- left item -->
    <div
      class="container mx-auto mb-10 md:h-[32rem] py-0 flex flex-col md:flex-row mx-auto md:pt-10 md:mt-10 space-y-0"
    >
      <div
        class="text-left mb-10 flex flex-col space-y-2 md:w-1/2 mt-10 md:mt-16 md:px-0 md:mb-0"
      >
        <h1
          class="max-w-lg text-brightRed font-black text-5xl text-left md:text-[50px] md:text-black md:font-black"
        >
          Simple Payments.<br />
          <div class="pt-5">Smiling Customers.</div>
        </h1>
        <div class="text-left text-lg text-[#241023] md:text-left">
          Easy payment solutions for you and<br />
          your customers
        </div>
        <div class="flex text-left pt-10 md:justify-start">
          <div class="mr-10 getStarted">
            <button
              (click)="sales()"
              class="px-6 p-2 font-bold bg-brightRed text-white text-xs rounded-md baseline md:block md:bg-faintBtn md:text-brightRed"
            >
              Get Started
            </button>
          </div>
          <div
            (click)="sales()"
            class="mt-1 font-extrabold text-[18px] cursor-pointer"
          >
            or contact sales
          </div>
        </div>
      </div>
      <!-- image -->
      <div
        class="hidden banner-bg bg-brightRedLight md:w-1/2 md:block flex"
      ></div>
      <div
        class="mt-5 banner-bg block bg-brightRedLight md:w-1/2 md:hidden md:mt-0 flex"
      >
        <!-- ihekkk -->
        <img src="assets/images/headerImage.png" alt="" />
      </div>
    </div>
  </div>
</section>

<!-- short text -->
<section>
  <div class="container flex mx-auto mt-20">
    <h2
      class="text-3xl md:text-4xl px-6 font-black text-center md:text-center md:px-0 text-[#B01E31]"
    >
      You’re in good company. Payfixy is tried and trusted by
      <span>leaders in the Nigerian corporate world.</span>
    </h2>
  </div>
</section>

<!-- Sponsor section -->
<section>
  <div class="container px-6 mx-auto mt-10 md:mt-28 text-center">
    <div class="flex justify-between">
      <!-- <div><img src="assets/images/sponsor1.png" alt=""></div> -->
      <div><img src="assets/images/sponsor2.png" width="70%" alt="" /></div>
      <div><img src="assets/images/sponsor3.png" width="70%" alt="" /></div>
      <div><img src="assets/images/sponsor4.png" width="70%" alt="" /></div>
      <div><img src="assets/images/sponsor5.png" width="70%" alt="" /></div>
      <div><img src="assets/images/sponsor6.png" width="70%" alt="" /></div>
      <div><img src="assets/images/sponsor7.png" width="70%" alt="" /></div>
    </div>
    <div class="mt-5 flex justify-between">
      <!-- <div><img src="assets/images/sponsor1.png" alt=""></div> -->
      <div><img src="assets/images/payCenter.png" width="60%" alt="" /></div>
      <div><img src="assets/images/flutterwave.svg" width="60%" alt="" /></div>
      <div><img src="assets/images/netpost.png" width="70%" alt="" /></div>
      <div><img src="assets/images/innovate.png" width="70%" alt="" /></div>
      <div><img src="assets/images/sponsor1.png" width="70%" alt="" /></div>
      <div><img src="assets/images/sponsoros.png" width="70%" alt="" /></div>
      <div><img src="assets/images/sponsor8.png" width="70%" alt="" /></div>
      <div><img src="assets/images/sponsor9.png" width="70%" alt="" /></div>
    </div>
  </div>
</section>

<!-- solution -->
<div
  class="relative flex flex-col-reverse mb-10 mt-10 md:mt-40 md:flex-row items-center space-y-0"
>
  <!-- left item -->
  <div class="container mx-auto mt-10 px-6 md:mt-0">
    <h2 class="font-black mb-10 text-4xl text-left md:text-5xl">
      The Ultimate Solution for<br />
      <span class="text-[#B01E31]">Hassle-Free</span> Transactions
    </h2>
    <p
      class="text-left text-[14px] md:text-[20px] font-bold md:mt-5 md:mb-5 md:font-extrabold"
    >
      Experience Seamless Payments for You and<br />Your Customers with Payfixy
    </p>
    <div class="justify-center md:justify-start">
      <div class="flex mt-5">
        <div class="mr-2">
          <img src="assets/images/check.png" width="75%" alt="" />
        </div>
        <div class="text-md">
          Effortlessly Set Up and Receive Instant Payments to Your Local Bank
          Account
        </div>
      </div>
      <div class="flex mt-5">
        <div class="mr-2">
          <img src="assets/images/check.png" width="75%" alt="" />
        </div>
        <div class="text-md">
          Get Affordable Pricing with Unmatched Value for Your Money
        </div>
      </div>
      <div class="flex mt-5">
        <div class="mr-2">
          <img src="assets/images/check.png" width="75%" alt="" />
        </div>
        <div class="text-md">
          Transform Your Business with Seamlessly Smooth Transactions
        </div>
      </div>
      <div class="mt-10 getStarted">
        <button
          (click)="sales()"
          class="px-6 p-2 font-bold text-faintBtnText text-xs bg-faintBtn rounded-md baseline hove:bg-brightRedLight md:block"
        >
          Get Started
        </button>
      </div>
    </div>
  </div>
  <!-- image -->
  <img
    src="assets/images/image.png"
    alt=""
    class="hidden absolute right-0 xl:h-[35rem] md:h-[20rem] z-[-1] md:block"
  />
  <div class="block banner-bg px-0 bg-brightRedLight md:w-1/2 md:hidden flex">
    <!-- ihekkk -->
    <img src="assets/images/bizmen.png" alt="" />
  </div>
</div>

<!-- tranxact section -->

<section class="mt-20 md:mt-40">
  <div
    class="container flex flex-col md:space-x-8 md:mt-40 md:flex-row mx-auto"
  >
    <!-- left item -->
    <div
      id="service-card"
      class="flex flex-col bg-[#F7F7F7] transact rounded-md md:w-1/2"
    >
      <div class="py-20 px-10">
        <div class="flex">
          <div class="mr-5">
            <img width="70%" src="assets/images/transact.svg" alt="" />
          </div>
          <div class="font-bold text-3xl mt-2 text-[#241023]">TranXact</div>
        </div>
        <div class="mt-10 font-extrabold text-[#241023] text-2xl">
          Effortlessly Sell Online<br />
          Without a Website
        </div>

        <div class="mt-10 text-[15px] font-semibold text-[#241023]">
          Sell anywhere; in-store, online, or on-the-go<br />
          using TranXact’s payment link(s)
        </div>

        <div
          class="mt-10 cursor-pointer underline text-[#B11E31]"
          (click)="sales()"
        >
          Get Started
        </div>
      </div>
    </div>

    <div
      id="service-card"
      class="flex flex-col mt-20 transact md:mt-0 md:w-1/2"
    >
      <div class="py-20 px-10 rounded-lg bg-[#F7F7F7]">
        <div class="flex">
          <div class="mr-8">
            <img src="assets/images/PayX-icon.png" width="60px" alt="" />
          </div>
          <div class="font-bold text-3xl mt-2 text-[#241023]">PayX</div>
        </div>
        <div class="mt-10 font-extrabold text-[#241023] text-2xl">
          Smart Donation Campaigns<br />
          Made Easy with Pay-X
        </div>

        <div class="mt-10 text-[15px] font-semibold text-[#241023]">
          Effortlessly create smart campaigns to receive<br />
          donations from supporters globally
        </div>

        <div
          class="mt-10 cursor-pointer underline text-[#B11E31]"
          (click)="sales()"
        >
          Get Started
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Why Choose -->

<section>
  <div class="flex relative container rounded-lg bg-[#b11e31] mt-20 mx-auto">
    <div class="hidden md:block">
      <div class="realtive px-10 my-16">
        <div class="text-white font-extrabold text-4xl">
          Why choose Payfixy?
        </div>
        <div class="text-white mt-10 text-sm md:text-2xl">
          It doesn’t matter what kind of business you run, as long<br />
          as you want to take your customers’ payment experience<br />
          to the next level, Payfixy’s your best bet!
        </div>
        <div class="mt-10 cursor-pointer underline text-white">Learn more</div>
      </div>
    </div>
    <div class="block md:hidden">
      <div class="realtive px-10 my-16">
        <div class="text-white font-extrabold text-4xl">
          Why choose Payfixy?
        </div>
        <div class="text-white mt-10 text-[20px] md:text-2xl">
          It doesn’t matter what kind of business<br />
          you run, as long as you want to take your<br />
          customers’ payment experienceto the<br />
          next level, Payfixy’s your best bet!
        </div>
        <div class="mt-10 cursor-pointer underline text-white">Learn more</div>
      </div>
    </div>
    <img
      src="assets/images/choose.png"
      width="20%"
      class="hidden md:block absolute right-0"
      alt=""
    />
  </div>
</section>

<!-- advert session -->
<section>
  <div
    class="flex px-6 container mx-auto mt-20 md:space-x-5 flex-col md:flex-row"
  >
    <div
      id="step-card"
      class="relative bg-[#F7F7F7] rounded-lg px-5 py-10 md:w-1/4"
    >
      <div
        class="text-brightRed font-extrabold md:font-extrabold text-2xl text-brightRed"
      >
        Multiple ways<br />
        to receive money
      </div>
      <div
        class="md:text-[16px] text-[18px] mb-20 font-semibold md:font-bold text-[#241023] mt-5"
      >
        Whether credit or debit cards, USSD or online transfer, Payfixy will let
        you receive funds anyway, anywhere. Payfixy accepts all major debit and
        credit cards both locally and internationally.
      </div>
      <div class="absolute bottom-0 md:mb-10 mb-5">
        <img
          class="bg-brightRed p-2 rounded-md"
          src="assets/images/arrow-right.svg"
          alt=""
        />
      </div>
    </div>
    <div
      id="step-card"
      class="relative bg-[#F7F7F7] rounded-lg mt-10 px-5 py-10 md:mt-0 md:w-1/4"
    >
      <div
        class="text-brightRed font-extrabold md:font-extrabold text-2xl text-brightRed"
      >
        No network.<br />
        No problem!
      </div>
      <div
        class="md:text-[16px] text-[18px] mb-20 md:font-bold font-semibold text-[#241023] mt-5"
      >
        Don’t let network problems stop you from receiving your money. Payfixy
        lets your customers pay you even when network is taking a break.
      </div>
      <div class="absolute bottom-0 md:mb-10 mb-5">
        <img
          class="bg-brightRed p-2 rounded-md"
          src="assets/images/arrow-right.svg"
          alt=""
        />
      </div>
    </div>
    <div
      id="step-card"
      class="relative bg-[#F7F7F7] rounded-lg mt-10 pb-20 px-5 py-10 md:mt-0 md:w-1/4"
    >
      <div
        class="text-brightRed font-extrabold md:font-extrabold text-2xl text-brightRed"
      >
        Guaranteed<br />
        safety and<br />
        security
      </div>
      <div
        class="md:text-[16px] text-[18px] mb-20 md:font-bold font-semibold text-[#241023] mt-5"
      >
        Payfixy is a CBN-licensed platform that ensures compliance with local,
        national, and international financial services regulations. So, your
        money is in good hands!
      </div>
      <div class="absolute bottom-0 md:mb-10 mb-5">
        <img
          class="bg-brightRed p-2 rounded-md"
          src="assets/images/arrow-right.svg"
          alt=""
        />
      </div>
    </div>
    <div
      id="step-card"
      class="relative bg-[#F7F7F7] rounded-lg mt-10 px-5 py-10 md:mt-0 md:w-1/4"
    >
      <div
        class="text-brightRed font-extrabold md:font-extrabold text-2xl text-brightRed"
      >
        Effortless<br />
        payments<br />
        on-the-go
      </div>
      <div
        class="md:text-[16px] mb-20 text-[18px] md:font-bold font-semibold text-[#241023] mt-5"
      >
        No more time wasted on cashier errors, incorrect calculations or dealing
        with change. Your<br />
        customers will love how easy it is to pay, and so will you!
      </div>
      <div class="absolute bottom-0 md:mb-10 mb-5">
        <img
          class="bg-brightRed p-2 rounded-md"
          src="assets/images/arrow-right.svg"
          alt=""
        />
      </div>
    </div>
  </div>
</section>

<section>
  <div class="flex mt-20 bg-[#241023] px-10 pt-12">
    <div class="container mx-auto mb-20">
      <div
        class="md:text-[65px] font-extrabold text-[23px] md:font-extrabold text-[#FFDEEB]"
      >
        Designed for business growth
      </div>
      <p class="text-white text-[21px] mt-2">
        Payfixy is one platform that offers all the digital payment solutions
        your business needs to thrive.
      </p>

      <div class="mt-16 md:text-3xl font-extrabold text-[24px] text-white">
        Set up your online payment channel
      </div>
      <p class="text-white text-[18px] mt-5">
        Build and grow your online business with digital payment made simple.<br />
        With Payfixy, you can accept payments from your customers in a fast,
        easy<br />
        and secure way. Customers can pay by:
      </p>
      <div class="mt-10 mb-20 cursor-pointer underline text-white">
        Learn more
      </div>

      <div class="block md:flex md:space-x-5 space-x-0">
        <div id="card_payments" class="md:hidden block flex justify-between">
          <div class="card">
            <div id="card-bg" class="relative text-center w-30 h-30 text-white">
              <div class="credit-card">
                <img src="assets/images/card.png" width="55%" alt="" />
              </div>
              <div class="mt-10 text-[#E5E9FF]">Credit card</div>
            </div>
          </div>
          <div class="card">
            <div
              id="card-bg"
              class="p-0 m-0 relative text-center w-30 h-30 md:mt-0 text-white"
            >
              <div class="debit-card">
                <img src="assets/images/debit-card.png" width="55%" alt="" />
              </div>
              <div class="mt-10 text-[#E5E9FF]">Debit card</div>
            </div>
          </div>
        </div>

        <div class="md:hidden mt-5 block flex justify-between">
          <div class="card w-1/2">
            <div id="card-bg" class="relative text-center w-35 h-35 text-white">
              <div class="credit-card">
                <img src="assets/images/cash.png" width="55%" alt="" />
              </div>
              <div class="mt-10 text-[#E5E9FF]">Cash</div>
            </div>
          </div>
          <div class="card">
            <!-- <div id="card-bg" class="p-0 m-0 relative text-center w-30 h-30 md:mt-0 text-white">
                            <div class="debit-card">
                                <img src="assets/images/debit-card.png" width="55%" alt="">
                            </div>
                            <div class="mt-10 text-[#E5E9FF]">Debit card</div>
                        </div> -->
          </div>
        </div>

        <div class="hidden md:block card">
          <div id="card-bg" class="relative text-center w-40 h-40 text-white">
            <div class="credit-card">
              <img src="assets/images/card.png" width="55%" alt="" />
            </div>
            <div class="mt-10 text-[#E5E9FF]">Credit card</div>
          </div>
        </div>
        <div class="hidden md:block card">
          <div
            id="card-bg"
            class="mt-5 p-0 m-0 relative text-center w-40 h-40 md:mt-0 text-white"
          >
            <div class="debit-card">
              <img src="assets/images/debit-card.png" width="55%" alt="" />
            </div>
            <div class="mt-10 text-[#E5E9FF]">Debit card</div>
          </div>
        </div>
        <div class="hidden md:block card">
          <div
            id="card-bg"
            class="mt-10 px-0 relative text-center w-40 h-40 md:mt-0 text-white"
          >
            <div class="cash">
              <img src="assets/images/cash.png" width="43%" alt="" />
            </div>
            <div class="mt-10 text-[#E5E9FF]">Cash</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- earn and sent invoice -->
<section>
  <div
    class="container mt-20 px-6 flex flex-col md:space-x-5 md:flex-row mx-auto"
  >
    <!-- left item -->
    <div
      id="invoice-img"
      class="flex justify-center text-center flex-col bg-[#E5E9FF] rounded-lg md:w-1/2"
    >
      <div class="py-10 px-10">
        <div class="md:font-bold font-black text-2xl">
          Create and send invoices
        </div>
        <p class="md:text-md text-[18px] font-semibold md:font-semibold mt-10">
          Custom-design professional invoices from your<br />
          mobile device and send them to customers for<br />
          quick and disputeless settlements.
        </p>
        <div class="mt-10 cursor-pointer underline text-[#b11e31]">
          Learn more
        </div>

        <div class="mt-10 flex justify-center">
          <img src="assets/images/invoice.png" width="25%" alt="" />
        </div>
      </div>
    </div>

    <div
      id="invoice-img"
      class="flex justify-center mt-10 text-center flex-col rounded-lg bg-[#F7C5AA] md:mt-0 md:w-1/2"
    >
      <div class="py-10 px-10">
        <div class="md:font-bold font-black text-2xl">
          Get your earned revenue
        </div>
        <p class="text-md text-[18px] font-semibold md:font-semibold mt-10">
          With Payfixy, what you get is what you see!<br />
          No hidden charges. No unexpected fees.<br />
          Plus, our partnership with banks ensures your<br />
          funds are promptly deposited.
        </p>
        <div class="mt-10 cursor-pointer underline text-[#b11e31]">
          Learn more
        </div>

        <div id="invoice-img" class="mt-10 flex justify-center">
          <img src="assets/images/naira-growth.png" width="28%" alt="" />
        </div>
      </div>
    </div>
  </div>
</section>

<section>
  <div class="contact-us flex justify-center text-center mt-20">
    <div>
      <div
        class="pt-12 text-2xl md:font-extrabold font-extrabold text-white md:text-5xl"
      >
        Simple, hassle-free setup to<br />
        get your business running.
      </div>
      <div class="flex justify-center text-center mt-10 mb-20">
        <div class="mr-2">
          <button
            (click)="sales()"
            class="px-6 p-2 font-bold text-faintBtnText text-xs bg-white rounded-md baseline hove:bg-brightRedLight md:block"
          >
            Get Started
          </button>
        </div>
        <div>
          <button
            (click)="sales()"
            class="px-6 p-2 font-bold text-faintBtnText text-xs bg-white rounded-md baseline hove:bg-brightRedLight md:block"
          >
            Contact Sales
          </button>
        </div>
      </div>
    </div>
  </div>
</section>

<section>
  <div class="mt-20 flex px-6 justify-center text-center md:px-0">
    <div class="text-2xl md:text-4xl font-semibold">
      More enabling features for business Growth
    </div>
  </div>
</section>

<!-- Features -->
<section>
  <div
    class="container flex px-6 flex-col md:space-x-5 md:flex-row mx-auto mt-10"
  >
    <!-- left item -->
    <div class="flex flex-col bg-[#F7F7F7] transact rounded-md md:w-1/3">
      <div class="py-10 px-5">
        <div class="flex">
          <div class="mr-5">
            <img width="70%" src="assets/images/transact.svg" alt="" />
          </div>
        </div>
        <div
          class="mt-10 md:font-semibold text-1xl text-3xl font-semibold text-[#241023] text-md"
        >
          Transaction <br />
          management
        </div>

        <div class="mt-5 text-[#241023] font-semibold text-[16px]">
          Manage, track and monitor<br />
          all your transactions.
        </div>

        <!-- <div class="mt-10 cursor-pointer underline text-[#B11E31]">
                    Learn more
                </div> -->
      </div>
    </div>

    <div
      class="flex flex-col bg-[#F7F7F7] mt-10 transact rounded-md md:mt-0 md:w-1/3"
    >
      <div class="py-10 px-5">
        <div class="flex">
          <div class="mr-5">
            <img width="70%" src="assets/images/reporting.png" alt="" />
          </div>
        </div>
        <div class="mt-10 md:font-bold font-semibold text-3xl text-[#241023]">
          Reporting
        </div>

        <div class="mt-10 font-semibold text-[#241023] text-[16px]">
          View transaction reports,<br />
          find new routes for growth.
        </div>

        <div class="mt-10 cursor-pointer underline text-[#B11E31]"></div>
      </div>
    </div>

    <div
      class="flex flex-col bg-[#F7F7F7] mt-10 transact rounded-md md:mt-0 md:w-1/3"
    >
      <div class="py-10 px-5">
        <div class="flex">
          <div class="mr-5">
            <img width="70%" src="assets/images/notification.png" alt="" />
          </div>
        </div>
        <div
          class="mt-5 md:font-bold font-semibold text-3xl text-[#241023] text-md"
        >
          Real-time<br />
          notification
        </div>

        <div class="mt-10 text-[#241023] font-semibold text-[16px]">
          Get notified of transactions<br />
          in real-time.
        </div>

        <!-- <div class="mt-10 cursor-pointer underline text-[#B11E31]">
                    Learn more
                </div> -->
      </div>
    </div>
  </div>
</section>

<section class="relative mb-20 md:mt-40 md:mb-40">
  <img
    src="assets/images/api.png"
    class="hidden absolute xl:h-[35rem] md:top-[-5rem] left-0 md:h-[25rem] xl:top-[-6rem] md:block"
    alt=""
  />
  <div
    class="container flex flex-col md:flex-row justify-end mx-auto md:w-full space-y-0"
  >
    <img
      src="assets/images/api.png"
      class="md:absolute left-0 md:h-[20rem] md:hidden"
      alt=""
    />
    <div class="px-6 md:px-0">
      <h2
        class="text-3xl text-left md:font-black text-brightRed md:text-4xl md:font-light"
      >
        Hello Developers,
      </h2>
      <p
        class="max-w-sm text-left text-2xl mt-5 font-semibold text-darkGrayishBlue"
      >
        Here’s an easy-peasy way to<br />
        integrate digital payment into<br />
        your website.
      </p>

      <p
        class="hidden md:block md:max-w-sm text-[17px] font-semibold mt-5 text-[#241023] md:text-left"
      >
        We built Payfixy from the ground up with an open<br />
        API framework to facilitate seamless integrations.
      </p>

      <p
        class="block md:hidden md:max-w-sm text-[17px] font-semibold mt-5 text-[#241023] md:text-left"
      >
        We built Payfixy from the ground up with an open API framework to
        facilitate seamless integrations.
      </p>

      <div class="mt-5 getStarted">
        <button
          class="hidden px-6 p-2 font-bold text-faintBtnText text-xs bg-faintBtn rounded-md baseline hove:bg-brightRedLight md:block"
        >
          Get API
        </button>
      </div>
    </div>
  </div>
</section>

<!-- <section>
</section> -->
<div class="flex justify-center text-center mt-20 bg-[#fff0f6] py-20">
  <div>
    <div class="md:text-5xl">
      A
      <span class="text-brightRed font-bold">Perfect Payment Platform</span> for
    </div>

    <div class="mt-5 text-brightRed md:text-3xl font-semibold">
      BUSINESSES <span>|</span> ORGANISATIONS <span>|</span> GOVERNMENT AGENCIES
      <span>|</span> INDIVIDUALS
    </div>

    <p class="font-semibold text-2xl mt-8">
      Simple, hassle-free setup to get your business running.
    </p>
    <div class="flex justify-center mt-5 mb-10">
      <button
        (click)="sales()"
        class="hidden px-6 p-2 font-bold text-white text-xs bg-brightRed rounded-md baseline hove:bg-brightRedLight md:block"
      >
        Get Started
      </button>
    </div>
  </div>
</div>
