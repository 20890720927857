<div class="container relative mt-10 md:flex-row mx-auto">
  <!-- left item -->
  <div class="max-w-3xl px-6 space-y-6 md:px-0">
    <h2 class="font-black text-5xl text-left md:text-[57px] md:font-bold font-sanMedium">
      Contact Our Sales Team
    </h2>
    <p class="text-left md:text-[16px] text-[18px] font-extrabold">
      Payfixy is a revolutionary digital payment platform that enables both
      businesses and<br />
      consumers to transact seamlessly.
    </p>

    <p class="text-left md:text-[16px] text-[18px] font-extrabold">
      We are committed to providing our customers with the best possible
      experience.
    </p>

    <p class="text-left md:text-[16px] text-[18px] font-extrabold">
      Our trained sales team is available to meet your requests satisfactorily.
    </p>
  </div>
  <img
    src="assets/images/customer-service.png"
    class="block px-6 mt-5 md:mt-2 mb-5 md:hidden"
    alt=""
  />
</div>

<div
  class="container flex relative flex-col space-x-5 md:mt-10 md:pb-48 md:flex-row mx-auto"
>
  <div class="flex px-6 mb-10 md:px-0 flex-col mt-10 md:mb-0">
    <div class="text-2xl font-black">
      <div class="font-sanMedium">Contact Form</div>
    </div>
    <div class="flex bg-[#fff0f6] md:mt-3 pt-5 rounded-lg p-4 justify-center">
      <div>
        <div>
          <div class="text-sm">Your Name (Required)</div>
          <input type="text" size="32" class="p-1 outline-none" />
        </div>

        <div class="mt-3">
          <div class="text-sm">Your Email (Required)</div>
          <input type="text" size="32" class="p-1 w-full outline-none" />
        </div>

        <div class="mt-3">
          <div class="text-sm">Subject</div>
          <input type="text" size="32" class="p-1 w-full outline-none" />
        </div>

        <div class="mt-3">
          <div class="text-sm">Your Message</div>
          <textarea size="32" class="w-full" rows="6"></textarea>
        </div>
        <div class="flex justify-center pb-5 mt-5">
          <button
            class="px-12 p-2 font-bold bg-[#b11e31] text-xs text-white rounded-md w-full"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-center text-center md:text-left md:ml-10 md:mt-10">
    <div class="md:pt-5 md:mt-5">
      <p class="md:text-[16px] text-[18px] md:font-bold">
        <span class="font-black font-bold font-sanMedium">Lagos Office</span><br />39A, Fola Osibo Road.
        Lekki, Phase 1.Lagos.
      </p>

      <p class="mt-5 md:text-[16px] text-[18px] md:font-bold">
        <span class="font-black font-bold font-sanMedium">Abuja Office</span><br />Plot 1014m Fria Close,
        Wuse 2, Abuja
      </p>

      <p class="mt-5 md:text-[16px] text-[18px] md:font-bold">
        <span class="font-black font-bold font-sanMedium">Call</span><br />+234 903 22 93 077
      </p>

      <p class="mt-5 md:text-[16px] text-[18px] md:font-bold">
        <span class="font-black font-bold font-sanMedium">Email</span><br />Info@payfixy.com
      </p>
    </div>
  </div>
  <!-- bg-[#fff0f6] -->
</div>
<img
  src="assets/images/customer-service.png"
  class="hidden absolute right-0 z-[-1] xl:h-[50rem] md:h-[20rem] top-[16rem] md:block"
  alt=""
/>

<div class="flex justify-center text-center mt-20 bg-[#b01e31] py-20">
  <div>
    <div class="mt-5 text-white md:font-bold text-2xl md:text-5xl">
      Get your business started with simplified <br />
      digital payment today!
    </div>
    <div class="flex justify-center mt-10 mb-10">
      <button
        class="px-6 p-2 font-bold text-[#b01e31] text-xs bg-white rounded-md baseline md:block"
      >
        Get Started
      </button>
    </div>
  </div>
</div>
