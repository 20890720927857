<section>
  <div class="container mx-auto">
    <div class="developer top-bg-image">
      <div
        class="flex justify-center text-white text-4xl text-center mt-10 py-20"
      >
        <h1 class="text-lg md:mb-5 text-white">FAQs</h1>
      </div>
    </div>
  </div>
  <div
    class="relative flex flex-col mt-10 md:mt-20 md:flex-row items-center mx-auto space-y-0"
  >
    <!-- left item -->
    <div class="flex flex-col container mx-auto px-6 space-y-6">
      <h2
        class="max-width-m font-extrabold text-3xl text-left md:text-5xl md:font-black font-sanMedium text-[#B01E31]"
      >
        General Info
      </h2>
      <section>
        <!-- component -->
        <div class="m-2 space-y-2">
          <div
            class="group flex flex-col gap-2 rounded-lg bg-slate-100 p-5 text-black"
            tabindex="1"
          >
            <div class="flex cursor-pointer items-center justify-between">
              <span class="font-semibold">
                What are the supported industries and categories on Payfixy?
              </span>
              <img
                src="../../../assets/images/caret-down-solid.svg"
                class="h-6 w-6 transition-all duration-500 group-focus:-rotate-180"
              />
            </div>
            <div
              class="invisible h-auto max-h-0 items-center opacity-0 transition-all group-focus:visible group-focus:max-h-screen group-focus:opacity-100 group-focus:duration-1000"
            >
              Our robust payment infrastructure is built to facilitate payment
              for all businesses across all sectors of the economy. Here are
              several industries that can partner with us in providing their
              customers with easy payment solutions: E-commerce, hospitality,
              Logistics and transportation, food, recreation, FMCGs, Health,
              Government MDAs etc.
            </div>
          </div>

          <div
            class="group flex flex-col gap-2 rounded-lg bg-slate-100 p-5 text-black"
            tabindex="2"
          >
            <div class="flex cursor-pointer items-center justify-between">
              <span class="font-semibold"> Who is Payfixy? </span>
              <img
                src="../../../assets/images/caret-down-solid.svg"
                class="h-6 w-6 transition-all duration-500 group-focus:-rotate-180"
              />
            </div>
            <div
              class="invisible h-auto max-h-0 items-center opacity-0 transition-all group-focus:visible group-focus:max-h-screen group-focus:opacity-100 group-focus:duration-1000"
            >
              Payfixy is a payment service solutions provider licensed by the
              Central Bank of Nigeria, helping businesses and organizations
              facilitate payment with ease.
            </div>
          </div>

          <div
            class="group flex flex-col gap-2 rounded-lg bg-slate-100 p-5 text-black"
            tabindex="3"
          >
            <div class="flex cursor-pointer items-center justify-between">
              <span class="font-semibold">
                What types of businesses are eligible to use Payfixy?
              </span>
              <img
                src="../../../assets/images/caret-down-solid.svg"
                class="h-6 w-6 transition-all duration-500 group-focus:-rotate-180"
              />
            </div>
            <div
              class="invisible h-auto max-h-0 items-center opacity-0 transition-all group-focus:visible group-focus:max-h-screen group-focus:opacity-100 group-focus:duration-1000"
            >
              We are open and structured to doing business with businesses of
              various categories such as MSMEs, Corporations, Startups, retail
              businesses…
            </div>
          </div>

          <div
            class="group flex flex-col gap-2 rounded-lg bg-slate-100 p-5 text-black"
            tabindex="3"
          >
            <div class="flex cursor-pointer items-center justify-between">
              <span class="font-semibold">
                What payment options are available to my customers?
              </span>
              <img
                src="../../../assets/images/caret-down-solid.svg"
                class="h-6 w-6 transition-all duration-500 group-focus:-rotate-180"
              />
            </div>
            <div
              class="invisible h-auto max-h-0 items-center opacity-0 transition-all group-focus:visible group-focus:max-h-screen group-focus:opacity-100 group-focus:duration-1000"
            >
              Customers can make payments using Visa Cards, Master Cards, Cash
              at bank, and Virtual Account.
            </div>
          </div>
        </div>
      </section>

      <section>
        <h2
          class="max-width-m font-extrabold text-3xl text-left md:text-5xl md:font-black font-sanMedium text-[#B01E31]"
        >
          Security
        </h2>
        <section>
          <!-- component -->
          <div class="m-2 space-y-2">
            <div
              class="group flex flex-col gap-2 rounded-lg bg-slate-100 p-5 text-black"
              tabindex="1"
            >
              <div class="flex cursor-pointer items-center justify-between">
                <span class="font-semibold"> How secure is Payfixy? </span>
                <img
                  src="../../../assets/images/caret-down-solid.svg"
                  class="h-6 w-6 transition-all duration-500 group-focus:-rotate-180"
                />
              </div>
              <div
                class="invisible h-auto max-h-0 items-center opacity-0 transition-all group-focus:visible group-focus:max-h-screen group-focus:opacity-100 group-focus:duration-1000"
              >
                Part of our core values endeavours us to be keen on integrity
                and Trust. This ensures we comply with all regulatory
                requirements as a licensed company with additional
                certifications such as PCI-DSS.
              </div>
            </div>

            <div
              class="group flex flex-col gap-2 rounded-lg bg-slate-100 p-5 text-black"
              tabindex="2"
            >
              <div class="flex cursor-pointer items-center justify-between">
                <span class="font-semibold">
                  How secure is the customer's debit card information?
                </span>
                <img
                  src="../../../assets/images/caret-down-solid.svg"
                  class="h-6 w-6 transition-all duration-500 group-focus:-rotate-180"
                />
              </div>
              <div
                class="invisible h-auto max-h-0 items-center opacity-0 transition-all group-focus:visible group-focus:max-h-screen group-focus:opacity-100 group-focus:duration-1000"
              >
                In compliance with NDPR regulations, we do not store customer
                card information. Customer’s debit card information is safely
                secured from hackers or any cyber security threats; given our
                infrastructure is secure and protected from all forms of threats
                to customers' data.
              </div>
            </div>
          </div>
        </section>
      </section>

      <section>
        <h2
          class="max-width-m font-extrabold text-3xl text-left md:text-5xl md:font-black font-sanMedium text-[#B01E31]"
        >
          Support
        </h2>
        <section>
          <!-- component -->
          <div class="m-2 space-y-2">
            <div
              class="group flex flex-col gap-2 rounded-lg bg-slate-100 p-5 text-black"
              tabindex="1"
            >
              <div class="flex cursor-pointer items-center justify-between">
                <span class="font-semibold">
                  Do you offer customer support to end-users?
                </span>
                <img
                  src="../../../assets/images/caret-down-solid.svg"
                  class="h-6 w-6 transition-all duration-500 group-focus:-rotate-180"
                />
              </div>
              <div
                class="invisible h-auto max-h-0 items-center opacity-0 transition-all group-focus:visible group-focus:max-h-screen group-focus:opacity-100 group-focus:duration-1000"
              >
                You can rest assured that our customer support representatives
                will be available for assistance by telephone or email at
                <a href="mailto:support@payfixy.com">support@payfixy.com</a>
              </div>
            </div>

            <div
              class="group flex flex-col gap-2 rounded-lg bg-slate-100 p-5 text-black"
              tabindex="2"
            >
              <div class="flex cursor-pointer items-center justify-between">
                <span class="font-semibold">
                  How do I resolve Chargeback or fail transactions?
                </span>
                <img
                  src="../../../assets/images/caret-down-solid.svg"
                  class="h-6 w-6 transition-all duration-500 group-focus:-rotate-180"
                />
              </div>
              <div
                class="invisible h-auto max-h-0 items-center opacity-0 transition-all group-focus:visible group-focus:max-h-screen group-focus:opacity-100 group-focus:duration-1000"
              >
                <p>
                  This situation usually occurs either on transactions done via
                  debit cards or other channels like bank transfer, cash@bank
                  etc.
                </p>

                <div class="px-4">
                  <ul>
                    <li class="text-left md:text-[16px] text-[18px] my-4">
                      If the transaction was done via bank card and it Failed,
                      please visit your bank to log a complaint, and your bank
                      will engage our bank for resolution.
                    </li>
                    <li class="text-left md:text-[16px] text-[18px] my-4">
                      If it was done via other channels, please reach out to our
                      support team - support@payfixy.com
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div
              class="group flex flex-col gap-2 rounded-lg bg-slate-100 p-5 text-black"
              tabindex="1"
            >
              <div class="flex cursor-pointer items-center justify-between">
                <span class="font-semibold">
                  Fraud and how to resolve fraud claims
                </span>
                <img
                  src="../../../assets/images/caret-down-solid.svg"
                  class="h-6 w-6 transition-all duration-500 group-focus:-rotate-180"
                />
              </div>
              <div
                class="invisible h-auto max-h-0 items-center opacity-0 transition-all group-focus:visible group-focus:max-h-screen group-focus:opacity-100 group-focus:duration-1000"
              >
                Fraud commonly arises when an unscrupulous individual gains
                access to a customer's bank card information or online banking
                credentials, leading to unauthorized transactions on the
                customer's account.
                <p>Our Support Services for Fraud Incidents</p>
                <p>
                  We are here to assist with cases involving fraud claims. If
                  you've encountered such an issue, please don't hesitate to
                  contact us at xxxxxxx@payfixy.com or reach out to our hotline
                  on 08xxxxx. Your security and peace of mind are our top
                  priorities.
                </p>
              </div>
            </div>
          </div>
        </section>
      </section>

      <section>
        <h2
          class="max-width-m font-extrabold text-3xl text-left md:text-5xl md:font-black font-sanMedium text-[#B01E31]"
        >
          Payment
        </h2>
        <section>
          <!-- component -->
          <div class="m-2 space-y-2">
            <div
              class="group flex flex-col gap-2 rounded-lg bg-slate-100 p-5 text-black"
              tabindex="1"
            >
              <div class="flex cursor-pointer items-center justify-between">
                <span class="font-semibold">
                  How to Integrate with Payfixy
                </span>
                <img
                  src="../../../assets/images/caret-down-solid.svg"
                  class="h-6 w-6 transition-all duration-500 group-focus:-rotate-180"
                />
              </div>
              <div
                class="invisible h-auto max-h-0 items-center opacity-0 transition-all group-focus:visible group-focus:max-h-screen group-focus:opacity-100 group-focus:duration-1000"
              >
                <p class="my-4">
                  Our focus is to provide easy payment solutions for you and
                  your customers. We provide merchants with valuable options and
                  ease of integrating our payment gateway to your website with
                  no hassles.
                </p>
                <p class="my-4">
                  We have put together well-structured API documentation and
                  plugins to make it easy for your developers to integrate with
                  our platform.
                </p>
                <p class="my-4">
                  In no time you'll receive payment and customers transacting
                  via your website.
                </p>
              </div>
            </div>

            <div
              class="group flex flex-col gap-2 rounded-lg bg-slate-100 p-5 text-black"
              tabindex="2"
            >
              <div class="flex cursor-pointer items-center justify-between">
                <span class="font-semibold"> Saved Cards </span>
                <img
                  src="../../../assets/images/caret-down-solid.svg"
                  class="h-6 w-6 transition-all duration-500 group-focus:-rotate-180"
                />
              </div>
              <div
                class="invisible h-auto max-h-0 items-center opacity-0 transition-all group-focus:visible group-focus:max-h-screen group-focus:opacity-100 group-focus:duration-1000"
              >
                <p class="font-semibold">What are Stored Payment Cards?</p>
                <p class="my-4">
                  Stored Payment Cards is a convenient feature that allows
                  customers to securely save their payment card information
                  during checkout, eliminating the need to re-enter it for
                  future transactions on the same platform or with other Payfixy
                  merchants.
                </p>
                <p class="my-5">
                  Rest assured, this feature is entirely safe and encrypted,
                  ensuring the protection of your card details. Please visit our
                  privacy policy page for a detailed insight into how we handle
                  and process your data.
                </p>
                <div class="px-4">
                  <p class="font-semibold">What Are the Benefits?</p>
                  <ul>
                    <li class="text-left md:text-[16px] text-[18px] my-4">
                      o <span class="font-semibold"> Convenience:</span> Say
                      goodbye to the hassle of repeatedly inputting your card
                      details when making a transaction with a Payfixy merchant.
                    </li>
                    <li class="text-left md:text-[16px] text-[18px] my-4">
                      o
                      <span class="font-semibold">
                        Reduced Cart Abandonment:</span
                      >
                      A faster checkout process makes you less likely to abandon
                      your purchase before completion
                    </li>
                    <li class="text-left md:text-[16px] text-[18px] my-4">
                      o
                      <span class="font-semibold">Fewer Card Errors:</span>
                      Storing your card information minimizes the risk of making
                      errors when entering card details, such as the incorrect
                      CVV or card number.
                    </li>
                    <li class="text-left md:text-[16px] text-[18px] my-4">
                      o
                      <span class="font-semibold"> Enhanced Security:</span>This
                      feature can significantly decrease the occurrence of
                      fraudulent transactions, as only you have access to your
                      stored cards.
                    </li>
                  </ul>
                </div>

                <div class="px-4">
                  <p class="font-semibold">How Does It Work?</p>
                  <ul>
                    <li class="text-left md:text-[16px] text-[18px] my-4">
                      o
                      <span class="font-semibold">Saving a New Card:</span> When
                      a Payfixy merchant has the Stored Payment Cards feature
                      enabled, you'll encounter the option to 'Save this card
                      for faster checkouts' within the checkout popup where you
                      provide your card information.
                    </li>
                    <li class="text-left md:text-[16px] text-[18px] my-4">
                      o
                      <span class="font-semibold">
                        Making Payments with a Stored Card:</span
                      >
                      Your Stored Payment Cards profile is associated with the
                      email address you use for checkout and the phone number
                      you linked to the saved card.
                    </li>
                  </ul>
                </div>
                <p>
                  If you have a saved card associated with your email, you'll be
                  presented with the option to access it and complete your
                  payment using any of your stored cards or to proceed with a
                  different card.
                </p>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  </div>
  <div class="flex justify-center text-center mt-20 bg-[#b01e31] py-20">
    <div>
      <div class="mt-5 text-white md:font-bold text-2xl md:text-5xl">
        Get your business started with simplified <br />
        digital payment today!
      </div>
      <div class="flex justify-center mt-10 mb-10">
        <button
          class="px-6 p-2 font-bold text-[#b01e31] text-xs bg-white rounded-md baseline md:block"
        >
          Get Started
        </button>
      </div>
    </div>
  </div>
</section>
