<div
  id="about-us"
  class="relative flex flex-col mt-10 mb-10 md:mb-40 md:flex-row items-center mx-auto space-y-0"
>
  <!-- left item -->
  <img
    src="assets/images/about-header.png"
    class="block px-6 mt-5 md:mt-2 mb-5 md:hidden"
    alt=""
  />
  <div class="container flex flex-col mx-auto px-6">
    <h2
      class="max-w-md text-4xl font-extrabold text-left md:text-5xl md:font-black md:mt-10"
    >
      Our mission is to simplify the way people pay and get paid across Africa.
    </h2>

    <h2 class="text-brightRed text-2xl mt-10">WHO WE ARE</h2>
    <p
      class="text-left max-w-md text-[18px] text-wrap md:text-[16px] font-semibold md:mt-4"
    >
      At Payfixy we help you build and grow your business through our multiple
      seamless payment solutions. We offer all the digital payment solutions
      your business needs to thrive. Following a successful transition process,
      Payfixy has taken responsibility of all payment solutions previously
      managed by its sister company Innovate1Pay. Our mission is to provide
      access to lifestyle and payment services for public/ private sectors and
      individuals through our World Class Platforms.
    </p>

    <p
      class="max-w-md text-left md:text-[16px] text-[18px] text-wrap font-semibold mt-5 mb-5"
    >
      Payfixy Limited is an indigenous Nigerian company duly registered as a
      limited liability company.
    </p>

    <p
      class="hidden md:block text-left text-[18px] text-wrap md:text-[16px] font-semibold"
    >
      Our business objectives include the deployment of a secure payment
      platform with multiple applications<br />
      and functionalities in furtherance of the provision of digital payment
      services for applicants/consumers<br />
      within the e-payments space.
    </p>

    <p
      class="block md:hidden text-left text-[19px] text-wrap md:text-[16px] font-semibold"
    >
      Our business objectives include the deployment of a secure payment
      platform with multiple applications and functionalities in furtherance of
      the provision of digital payment services for applicants/consumers within
      the e-payments space.
    </p>
  </div>
  <!-- image -->
  <img
    src="assets/images/about.png"
    class="hidden absolute z-[-1] right-0 xl:h-[45rem] md:h-[28rem] md:top-[-12rem] md:block"
    alt=""
  />
</div>

<section class="relative mb-20 md:mt-40 md:mb-40">
  <img
    src="assets/images/ceo.png"
    class="hidden absolute left-0 z-[-1] xl:h-[30rem] md:top-[5rem] md:h-[20rem] xl:top-[-6rem] md:block"
    alt=""
  />
  <div
    class="container flex flex-col md:flex-row justify-end mx-auto md:w-full space-y-0"
  >
    <img
      src="assets/images/ceo.png"
      class="md:absolute left-0 md:h-[20rem] md:hidden"
      alt=""
    />
    <div class="px-6 md:px-0 md:w-1/2">
      <p class="md:text-[16px] text-[18px] text-wrap font-semibold text-left">
        Our company is a CBN-licensed Payment Service Solution Provider (PSSP)
        and a<br />
        major provider of digital and electronic payment processing services in
        Nigeria with<br />
        millions of client transactions and payments processed in Nigeria on our
        platform.<br />
      </p>

      <p
        class="md:text-[16px] text-[18px] font-semibold text-wrap mt-5 text-left"
      >
        We are currently engaged in the provision of payment solution services
        through our<br />
        affiliate company (SW Global Limited) which provides cutting-edge
        technology<br />
        services for the implementation of the following projects in Nigeria:
      </p>

      <div class="hidden mt-5 justify-center md:justify-start md:block">
        <div class="flex">
          <div class="mr-2">
            <img src="assets/images/check.png" width="70%" alt="" />
          </div>
          <div class="text-[15px] font-semibold text-wrap">
            Nigeria Driver’s License
          </div>
        </div>
        <div class="flex mt-2">
          <div class="mr-2">
            <img src="assets/images/check.png" width="70%" alt="" />
          </div>
          <div class="text-[15px] font-semibold text-wrap">
            Nigerian Passport and Visa Processing and Issuance
          </div>
        </div>
        <div class="flex mt-2">
          <div class="mr-2">
            <img src="assets/images/check.png" width="70%" alt="" />
          </div>
          <div class="text-[15px] font-semibold text-wrap">
            Nursing and Midwifery Council of Nigeria (NMCN) e-Portal Project
          </div>
        </div>
        <!-- <div class="flex mt-2">
                    <div class="mr-2"><img src="assets/images/check.png" width="70%" alt=""></div>
                </div> -->
        <div class="text-[17px] font-extrabold mt-5 pb-2 italic text-wrap">
          Registration and Payments
        </div>
        <div class="flex mt-2">
          <div class="mr-2">
            <img src="assets/images/check.png" width="70%" alt="" />
          </div>
          <div class="text-[15px] font-semibold text-wrap">
            Council for the Regulation of Freight Forwarding in Nigeria (CRFFN)
            Registration<br />
            and Import Payment and Processing
          </div>
        </div>
      </div>
      <p
        class="md:text-[15px] text-[18px] font-semibold text-wrap mt-5 text-left"
      >
        Our payment platform is customized for customers’ needs with multiple
        payment<br />
        channels to ensure ease of payments and is positioned as one of the
        largest Nigerian<br />
        payment platforms in Nigeria.
      </p>
      <div class="hidden mt-5">
        <button
          class="hidden px-6 p-2 font-bold text-faintBtnText text-xs bg-faintBtn rounded-md baseline hove:bg-brightRedLight md:block"
        >
          Get Started
        </button>
      </div>
    </div>
  </div>
</section>

<section>
  <div class="flex relative md:mt-40 container rounded-lg bg-[#b11e31] mx-auto">
    <div>
      <div class="px-10 my-16">
        <p class="text-white font-light mb-2">WHAT WE BUILT</p>
        <div class="text-white font-semibold text-2xl">
          An innovative approach to global eCommerce,<br />
          currency and transaction management.
        </div>
        <div class="text-white font-semibold mt-10 text-[15px]">
          Today’s technological advancements have created new opportunities for
          business owners to prosper. Payfixy helps build and grow businesses by
          providing a safe and efficient digital platform that fosters fast and
          seamless payments.
        </div>
        <div class="text-white mt-5 font-semibold text-[15px]">
          Payfixy uses state-of-the-art technology to streamline the payment
          process, creating a more efficient approach to commerce, especially
          eCommerce. Our proprietary technology together with our mobile app and
          Point of Sale (POS) makes accepting payments easier than ever before.
        </div>
      </div>

      <img
        src="assets/images/choose.png"
        width="15%"
        class="hidden bg-img md:block absolute right-0"
        alt=""
      />
    </div>
  </div>
</section>

<section class="mt-20">
  <div class="px-6 container mx-auto text-center mb-20 md:px-0">
    <div class="font-semibold md:font-black text-2xl font-sanMedium">OUR TEAM</div>
    <p class="text-[18px] md:font-[15px] font-semibold md:text-lg mt-5">
      Our solutions are built by an experienced team with deep domain expertise
      in payments, fraud prevention <span class="hidden"><br /></span>
      and risk management. Our team has worked at companies such as Google and
      MasterCard to name a few.
    </p>
  </div>
  <div
    class="container px-6 flex justify-center flex-col md:space-x-8 md:flex-row md:px-0 mx-auto"
  >
    <div
      class="flex mt-5 flex-col rounded-md items-center text-center md:w-1/4"
    >
      <div class="md:team flex justify-center items-center small-img">
        <img src="assets/images/mahmood.png" alt="" />
      </div>
      <p class="text-sm mt-2 text-[#093a51] font-semibold font-sanMedium">
        Mahmood Ahmadu, OON, MIo
      </p>
      <span class="text-xs text-[#093a51] font-semibold mt-2"
        >Founder & Chairman</span
      >
    </div>

    <div
      class="flex items-center text-center mt-12 md:mt-0 flex-col transact rounded-md md:w-1/4"
    >
      <div class="md:team small-img flex justify-center items-center md:mt-5">
        <img src="assets/images/anthony.png" alt="" />
      </div>
      <p class="text-sm mt-2 text-[#093a51] font-semibold font-sanMedium">
        Mr. Anthony Nwachukwu
      </p>
      <span class="text-xs text-[#093a51] font-semibold mt-2"
        >Co-founder & CEO</span
      >
    </div>
    <!-- <div class="flex items-center mt-12 md:mt-0 flex-col transact rounded-md md:w-1/4">
            <div class="md:team small-img  flex justify-center items-center md:mt-5">
                <img src="assets/images/Morenike.png" alt="">
            </div>
            <p class="text-sm mt-2 text-[#093a51] font-semibold">Mrs. Morenike Olanipekun</p>
            <span class="text-xs text-[#093a51] font-semibold mt-2">General Manager</span>
        </div> -->
    <!-- <div
      class="flex items-center text-center mt-12 md:mt-0 flex-col transact md:w-1/4"
    >
      <div
        class="md:team small-img flex justify-center items-center flex justify-center items-center md:mt-5"
      >
        <img src="assets/images/Imade.png" class="items-center" alt="" />
      </div>
      <p class="text-sm mt-2 text-[#093a51] font-semibold font-sanMedium">
        Mrs. Imade George-Ogbonna
      </p>
      <span class="text-xs text-[#093a51] font-semibold mt-2"
        >Head, Compliance and<br />
        Risk Management</span
      >
    </div> -->
  </div>

  <div
    class="container flex justify-center flex-col md:mt-10 md:space-x-8 md:flex-row mx-auto"
  >
    <div
      class="flex items-center text-center mt-12 md:mt-0 flex-col transact md:w-1/4"
    >
      <div class="md:team s-small-img flex justify-center items-center md:mt-5">
        <img src="assets/images/Kelechi.png" alt="" />
      </div>
      <p class="text-sm mt-2 text-[#093a51] font-semibold font-sanMedium">
        Mr. Kelechi Dozie
      </p>
      <span class="text-xs text-[#093a51] font-semibold mt-2"
        >Managing Director</span
      >
    </div>

    <div
      class="flex items-center w-full text-center mt-12 md:mt-0 flex-col transact md:w-1/4"
    >
      <div class="md:team s-small-img flex justify-center items-center md:mt-5">
        <img src="assets/images/Abi.png" alt="" />
      </div>
      <p class="text-sm mt-2 text-[#093a51] font-semibold font-sanMedium">Abi Ahmed Haruna</p>
      <span class="text-xs text-[#093a51] font-semibold mt-2"
        >General Counsel & Company<br />
        Secretary</span
      >
    </div>
    <!-- <div class="flex flex-col mt-12 md:mt-0 md:w-2/4">
            <div class="flex justify-center text-center md:mt-20">
                <button class="px-6 p-2 font-light bg-[#b11e31] text-white text-xs rounded-md">
                    Join Our Team
                </button>
            </div>
        </div> -->
  </div>
</section>

<section>
  <div
    id="about-us"
    class="relative flex flex-col mt-10 mb-10 md:mb-40 md:mt-40 md:flex-row items-center mx-auto space-y-0"
  >
    <!-- left item -->
    <div class="container flex flex-col mx-auto px-6">
      <h2
        class="max-width-m font-extrabold text-4xl text-left md:text-5xl md:font-black"
      >
        Our Corporate<br />
        Social Responsibility
      </h2>
      <p
        class="hidden md:block text-left md:text-[15px] text-[18px] mt-10 mb-5 md:mb-0 font-extrabold md:mt-0 md:mt-10"
      >
        As a company, we take our corporate social responsibility <br />very
        seriously and strive to adhere to the highest standards<br />in ethical
        behaviour, ensure environmental sustainability,<br />
        and do our part to improve people’s lives<br />
        and the world at large.
      </p>
      <p
        class="block md:hidden text-left md:text-[15px] text-[18px] mt-10 mb-5 md:mb-0 font-extrabold md:mt-0 md:mt-10"
      >
        As a company, we take our corporate social responsibility very seriously
        and strive to adhere to the highest standards in ethical behaviour,
        ensure environmental sustainability, and do our part to improve people’s
        lives and the world at large.
      </p>
    </div>
    <!-- image -->
    <img
      src="assets/images/aboutus.png"
      class="hidden absolute right-0 h-[25rem] z-[-1] md:block"
      alt=""
    />
    <img
      src="assets/images/all-hands.png"
      class="block px-0 mt-5 md:mt-2 mb-5 md:hidden"
      alt=""
    />
  </div>
</section>

<section class="mt-20 px-6 md:px-0">
  <div class="container mx-auto text-center mb-20">
    <div>OUR CORE VALUES</div>
    <p class="text-[#b11e31] text-2xl font-black md:text-3xl mt-2">
      Why should you work with us?
    </p>
    <p class="text-[20px] mt-2">
      We ensure that our culture is always reflected in the way we transact
      business
    </p>
  </div>
  <div class="container flex flex-col mt-10 md:space-x-8 md:flex-row mx-auto">
    <div class="flex items-center flex-col transact rounded-md md:w-1/5">
      <div class="items-center">
        <div class="flex justify-center">
          <img src="assets/images/commitment.png" width="40%" alt="" />
        </div>
        <div class="text-center mb-5 mx-20">
          <p class="mt-2 text-2xl font-black mb-2">Commitment</p>
          <span class="md:text-sm text-1xl italic font-semibold"
            >We take a stand to help our customers through the help of our
            dedicated and diligent staff.</span
          >
        </div>
      </div>
    </div>

    <div
      class="flex items-center flex-col mt-5 md:mt-0 transact rounded-md md:w-1/5"
    >
      <div class="items-center">
        <div class="flex justify-center">
          <img src="assets/images/integrity.png" width="40%" alt="" />
        </div>
        <div class="text-center mx-20 md:mx-0">
          <p class="mt-2 text-2xl font-black mb-2">Integrity</p>
          <span class="md:text-sm text-1xl italic font-semibold"
            >As a team, we embrace<br />
            truth and build relationships<br />
            governed by integrity.</span
          >
        </div>
      </div>
    </div>
    <div
      class="flex items-center flex-col mt-5 md:mt-0 transact rounded-md md:w-1/5"
    >
      <div class="items-center">
        <div class="flex justify-center">
          <img src="assets/images/transparency.png" width="40%" alt="" />
        </div>
        <div class="text-center mx-20 md:mx-0">
          <p class="mt-2 text-2xl font-black mb-2">Transparency</p>
          <span class="md:text-sm text-1xl italic font-semibold"
            >We are clear and<br />
            transparent in all our<br />
            dealings.</span
          >
        </div>
      </div>
    </div>
    <div
      class="flex items-center flex-col mt-5 md:mt-0 transact rounded-md md:w-1/5"
    >
      <div class="items-center">
        <div class="flex justify-center">
          <img src="assets/images/accountability.png" width="40%" alt="" />
        </div>
        <div class="text-center mx-20 md:mx-0">
          <p class="mt-2 text-2xl font-black mb-2">Accountability</p>
          <span class="md:text-sm text-1xl italic font-semibold"
            >We are accountable<br />
            and adhere to sound<br />
            corporate governance.</span
          >
        </div>
      </div>
    </div>

    <div
      class="flex items-center flex-col mt-5 md:mt-0 transact rounded-md md:w-1/5"
    >
      <div class="items-center">
        <div class="flex justify-center">
          <img src="assets/images/trust.png" width="35%" alt="" />
        </div>
        <div class="text-center mx-20 md:mx-0">
          <p class="mt-2 text-2xl font-black mb-2">Trust</p>
          <span class="md:text-sm text-1xl italic font-semibold"
            >We are led by honesty<br />
            and candid business<br />
            etiquettes.</span
          >
        </div>
      </div>
    </div>
  </div>
</section>

<div class="flex justify-center text-center mt-20 bg-[#b01e31] py-20">
  <div>
    <div class="mt-5 text-3xl text-white md:text-4xl">
      Get your business started with simplified <br />
      digital payment today!
    </div>
    <div class="flex justify-center mt-10 mb-10">
      <button
        class="px-6 p-2 font-bold text-brightRed text-xs bg-white rounded-md baseline hove:bg-brightRedLight md:block"
      >
        Get Started
      </button>
    </div>
  </div>
</div>
